import React from 'react'
import styles from './styles.module.css'
import { IoSettingsSharp } from "react-icons/io5";



export default function SecurityLog () {
  
  
  return (
    <div className={styles.wrapper}>
   
  
    <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>Дата Время </h2>
          <ul>
          <li className={styles.li}>27.11.2023 00:04:23</li>
          <li className={styles.li}>27.11.2023 00:03:38 </li>
          <li className={styles.li}>27.11.2023 00:05:47 </li>
          <li className={styles.li}>27.11.2023 00:01:43 </li>
          <li className={styles.li}>27.11.2023 00:02:25 </li>
          <li className={styles.li}>27.11.2023 00:03:08 </li>
          <li className={styles.li}>27.11.2023 00:07:51</li>    
          <li className={styles.li}>27.11.2023 00:13:05</li>    
          <li className={styles.li}>27.11.2023 00:01:15</li>    
          <li className={styles.li}>27.11.2023 00:08:21</li>    
          </ul>
        </div>
        </div>

        <div style={{display: "flex"}}>
          <div style={{width:"320px", justifyContent:"center"}}>
          <h2>Логин</h2>
            <ul>
            <li className={styles.li}>petr-ivanov</li>
            <li className={styles.li}>nik-sorok</li>
            <li className={styles.li}>vas-vaj</li>
            <li className={styles.li}>ark-nuj</li>
            <li className={styles.li}>petr-ivanov</li>
            <li className={styles.li}>nik-sorok</li>
            <li className={styles.li}>vas-vaj</li>
            <li className={styles.li}>ark-nuj</li>
            <li className={styles.li}>petr-ivanov</li>
            <li className={styles.li}>ark-nuj</li>
            </ul>
          </div>
        </div>

      <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>ФИО</h2>
          <ul>
          <li className={styles.li}>Иванов Петр Кириллович</li>
          <li className={styles.li}>Сорокин Николай Иванович</li>
          <li className={styles.li}>Важен Василий Петрович</li>
          <li className={styles.li}>Нужен Аркадий Александрович</li>
          <li className={styles.li}>Иванов Петр Кириллович</li>
          <li className={styles.li}>Сорокин Николай Иванович</li>
          <li className={styles.li}>Важен Василий Петрович</li>
          <li className={styles.li}>Нужен Аркадий Александрович</li>
          <li className={styles.li}>Иванов Петр Кириллович</li>
          <li className={styles.li}>Нужен Аркадий Александрович</li>
          </ul>
        </div>
      </div>


      <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>Действие</h2>
          <ul className= {styles.last}>
          <li className={styles.li}>Создание сообщения  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Редактирования сообщения  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Создание события  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Редактирование события  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Создание сообщения  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Редактирования сообщения  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Создание события  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Редактирование события  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Создание сообщения  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Редактирование события  <IoSettingsSharp size={20} color='#003f70'/></li>
          </ul>
        </div>
      </div>
    


  </div>
  )
}
