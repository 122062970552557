import React from 'react'
import styles from './Search.module.css'
import Button from '../../../components/Button'
import { risk } from '../../../assets/const'

const Search = ({date, setDate, handleSearch, mass_media}) => {
  return (
    <div className={styles.dateWrapper}>
        <div className={styles.inputBlock}>
            <span>Дата с:</span>
            <input type="date" value={date.from} onChange={e => setDate(prev => ({...prev, from: e.target.value}))}/>
        </div>

        <div className={styles.inputBlock}>
            <span>Дата до:</span>
            <input type="date" value={date.to} onChange={e => setDate(prev => ({...prev, to: e.target.value}))}/>
        </div>

        <div className={styles.wrapperSelect}>
            
            <div className={styles.wrapperSelectItem}>
                <span>Тип</span>
                <select className={styles.select} value={date.detected_risk} onChange={e => setDate(prev => ({...prev, detected_risk: e.target.value}))}>
                    <option value=''>Все</option>
                    {risk.map(el => (
                        <option key={el.id} value={el.title}>{el.title}</option>
                    ))}
                </select>
            </div>
            
            <div className={styles.wrapperSelectItem}>
                <span>Источник</span>
                <select className={styles.select} value={date.source} onChange={e => setDate(prev => ({...prev, source: e.target.value}))}>
                    <option value=''>Все</option>
                    {mass_media.map(el => (
                        <option key={el.id} value={el.id}>{el.source_name}</option>
                    ))}
                </select>
            </div>

        </div>
        <div className={styles.wrapperSelectItem} style={{padding: '1rem 0'}}>
            <span>По контексту</span>
            <input 
                type="text" 
                value={date.search} 
                name="search"
                className={styles.input}
                onChange={e => setDate(prev => ({...prev, search: e.target.value}))}/>
        </div>

        <Button onClick={handleSearch} mode='margin'>Поиск</Button>
    </div>
    
  )
}

export default Search