import {SET_MASS_MEDIA, SET_NEWS, SET_NEWS_LOADING} from "./types";
import {createNotification} from "../components/Notifications/Notifications";

export const buildDefaultHeaders = () => {
    return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }
  }

export const getNews = (date, page=0) => async(dispatch, getState) => {
    dispatch({ type: SET_NEWS_LOADING, payload: true });
    
    const params = {
      from: date.from,
      to: `${date.to}  23:59:59`,
      page: page,
      max: 50,
      detected_risk: date.detected_risk,
      source: date.source,
      search: date.search
    }

    let filtered = {};
    
    for (const property in params) {
      if (params[property]) {
        filtered[property] = params[property];
      }
    }

    const urlParams = new URLSearchParams(filtered).toString();

    const response = await fetch(`https://phb.nintegra.ru/api/news?${urlParams}`);
    const data = await response.json(); 
    dispatch({type: SET_NEWS, payload: data});
    dispatch({ type: SET_NEWS_LOADING, payload: false });
  };

export const editNews = (id, data, date) => async (dispatch, getState) => {
    dispatch({ type: SET_NEWS_LOADING, payload: true });
    if (1) {
        const response = await fetch(`https://phb.nintegra.ru/api/news/${id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              }, 
        })
        if (response.ok) {
            createNotification("success", "Изменено")
            dispatch(getNews(date));
        } else {
            createNotification("error", "Ошибка")
        }
    }
    dispatch({ type: SET_NEWS_LOADING, payload: false });
  }

    export const getMassMedia = () => async(dispatch, getState) => {
      const response = await fetch(`https://phb.nintegra.ru/api/sources`);
      const data = await response.json(); 
      dispatch({type: SET_MASS_MEDIA, payload: data});
    };