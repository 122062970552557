import React from 'react'
import styles from './styles.module.css'
import { ROLES } from '../../assets/const'
import Li from '../../components/Li'
import { IoSettingsSharp } from "react-icons/io5";



export default function Users () {
  
  
  return (
    <div className={styles.wrapper}>
   
  
    <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>ФИО</h2>
          <ul>
          <li className={styles.li}>Иванов Петр Кириллович</li>
          <li className={styles.li}>Сорокин Николай Иванович</li>
          <li className={styles.li}>Важен Василий Петрович</li>
          <li className={styles.li}>Нужен Аркадий Александрович</li>
          </ul>
        </div>
      </div>


      <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>Логин</h2>
          <ul>
          <li className={styles.li}>petr-ivanov</li>
          <li className={styles.li}>nik-sorok</li>
          <li className={styles.li}>vas-vaj</li>
          <li className={styles.li}>ark-nuj</li>
          </ul>
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{width:"320px", justifyContent:"center"}}>
        <h2>Роль</h2>
          <ul className= {styles.last}>
          <li className={styles.li}>Администратор  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Руководитель  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Мониторинг  <IoSettingsSharp size={20} color='#003f70'/></li>
          <li className={styles.li}>Оператор <IoSettingsSharp size={20} color='#003f70'/></li>
          </ul>
        </div>
      </div>
    


  </div>
  )
}
