import React, { useState, useEffect } from 'react'
import styles from './News.module.css'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../components/Title/Title';
import { risk } from '../../assets/const';
import { getNews, editNews, getMassMedia } from '../../actions/news'
import Loader from '../../components/Loader/Loader'
import { Pagination } from '../../components/Pagination/Pagination';
import { MdEdit, MdSave } from 'react-icons/md';
import Search from './Search/Search';
import { IoLanguageOutline } from "react-icons/io5";

const News = () => {
  const [itemList, setItemList] = useState([]);
  const [date, setDate] = useState({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')});
  const [activePage, setActivePage] = useState(0); // активная страница
  const [isEdit, setIsEdit] = useState('');
  const [editItem, setEditItem] = useState({});
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [translated, setTranslated] = useState([]);
  
  const dispatch = useDispatch();
  const news = useSelector(state => state.news);
  const { loading, mass_media } = news;

    useEffect(() => {
      dispatch(getNews(date, activePage));
    }, [])
    

  useEffect(() => {
      setItemList(news.data);
  }, [news])

  useEffect(() => {
    dispatch(getMassMedia());
  }, [])
  

  const changeAlarm = (data, id) => {
      setItemList(prev => ({...prev, detected_risk: data}))
      dispatch(editNews(id, {detected_risk: data}, date)); 
  }

  const handleSetActivePage = (number) => {
    setActivePage(number);
    dispatch(getNews(date, number));
}

    const handleSave = (data) => {
        dispatch(editNews(data.article_id, data, date));
        setIsEdit('');
    }

    const handleEdit = (news) => {
        setIsEdit(news.article_id);
        setEditItem(news);
    }

    const checkInfo = async(id) =>{
        const response = await fetch(`https://phb.nintegra.ru/api/testgptnews/${id}`);
        const data = await response.json(); 
        const question = data.gptResult.queries ? data.gptResult.queries[0].gptResult.question[0].content : data.gptResult.question[0].content
        const answer = data.parsedResult
        setQuestion(question)
        setAnswer(answer)
    }

    const handleTranslate = (val) => {
        if (translated?.find(el => el.id == val.article_id)) {
            setTranslated(prev => prev.filter(el => el.id != val.article_id));
        } else {
            setTranslated(prev => ([...prev, {name: val.article_ingress_translated, id: val.article_id}]));
        }
    }

    const handleSearch = () => {
        dispatch(getNews(date, activePage));
    }
  
    return (
    <div className={styles.wrapper}>
        <Title title='Новости'/>
    
        <Search date={date} setDate={setDate} handleSearch={handleSearch} mass_media={mass_media}/>
    
        <div className={styles.dateCounter}>Всего новостей: <span>{itemList?.num_articles}</span></div>
        <div className={styles.newsWrapper}>
            {loading ? <Loader/> : <></>}
            {itemList?.articles && itemList?.articles.map(news => {
                const isDanger = !!news.detected_risk && news.detected_risk !== 'нет'
                return (
                    <div 
                    className={styles.newsCard}
                    key={news.article_id}>
                    <h3>
                        <span
                            title='Номер'
                            className={styles.number}
                            style={{backgroundColor: isDanger ? 'red' : 'none'}}
                            >
                                <span className={styles.number_news}>№ {news?.article_id}</span>
                                {news.incident_id && 
                                <span className={styles.number_event} title='Перейти'>
                                    <a  href={`events/${news.incident_id}`} target="_blank">Событие № {news.incident_id}</a>
                                </span>
                                }
                        </span>

                            {isEdit == '' && !translated.find(el => el.id == news.article_id) ? <i className={styles.icon} onClick={() => handleEdit(news)}><MdEdit/></i> : <></>}
                            {isEdit == news.article_id ? <i className={styles.icon} onClick={() => handleSave(editItem)}><MdSave/></i> : <></>}
                            {!translated.find(el => el.id == news.article_id) ?
                            <>
                            {isEdit == news.article_id ? 
                                <textarea 
                                    className={styles.textarea} 
                                    type="text" 
                                    value={editItem.article_ingress || news.article_ingress} 
                                    onChange={e => setEditItem(prev => ({...prev, article_ingress: e.target.value}))}/>
                            : news.article_ingress}
                            </>
                            : <></>}
                            {
                                translated?.find(el => el.id == news.article_id) ?
                                <>{translated?.find(el => el.id == news.article_id)?.name}</>
                                : <></>
                            }

                            </h3>
                            {news.article_ingress_translated ? 
                                <div 
                                    className={styles.translate} 
                                    onClick={() => handleTranslate(news)}>
                                        {translated?.find(el => el.id == news.article_id) ? 'Оригинал' : 'Перевести'} <i><IoLanguageOutline/></i>
                                </div> : <></>}
                        
                        <div className={styles.risk}>
                        <div>Событие РХБ - </div> 
                        <select
                            value={news.detected_risk}
                            onChange={(e) => changeAlarm(e.target.value, news.article_id)}
                        >
                            <option key='нет' value='нет'>Нет</option>
                            {/* {[{id:0, title: news.detected_risk}, ...risk].map(el =>  */}
                            {risk.map(el => 
                            <option key={el.title} value={el.title}>
                                {el.title}
                            </option>    
                            )}
                        {/* {news.detected_risk == null ? 'Не проверялось' : news.detected_risk} */}
                        </select>
                    </div>
    


                    <div className={styles.wrapperSource}>
                    <a href={news.article_url} target='_blank'>Источник - {news.source_name}</a>
                    <button type="button" className={styles.button} onClick={()=> checkInfo(news.article_id)}>Проверить</button>
                    </div>
                        <span>{moment(news.date_added).format('DD.MM.YYYY, H:mm')}</span>
                        </div>
                        )
            })
                }      
                {answer &&
                    <div className={styles.newsModal}>
                        <div className={styles.newsModalContent}>
                            <div className={styles.newsModal__answer}>Ответ:   {answer}</div>
                            <div className={styles.newsModal__question}>Ответ полный:  {question?.split('\n')?.map((item, i) => i != 0 ? item : '')}</div>
                            <button style={{ position:"relative", left:"45%"}}className={styles.button} onClick={()=> setAnswer('')}>Закрыть</button>
                        </div>
                    </div>}  
              
        </div>
        {itemList?.num_articles ? 
        <Pagination pageCount={Math.ceil(itemList?.num_articles / 50)} numberPage={activePage} onPageChange={e => handleSetActivePage(e.selected)}/>
        : <></>}
    
    </div>
  )
}

export default News