import {
  SET_EVENTS, 
  SET_EVENTS_LOADING, 
  SET_EVENTS_BY_ID, 
  SET_EVENTS_NEWS, 
  SET_PDF_BY_ID, 
  SET_ANSWER_LOADING, 
  SET_ANSWER,
  SET_EVENTS_ASK_LOADING,
  SET_EVENTS_ASK
} from "./types";
import {createNotification} from "../components/Notifications/Notifications";
import moment from "moment";

export const buildDefaultHeaders = () => {
    return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }
  }

export const getEvents = (date, page=0, full = false) => async(dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    const response = await fetch(`https://phb.nintegra.ru/api/events?from=${date.from}&to=${date.to} 23:59:59${page === false ? '' : `&page=${page}`}${full ? '' : '&max=10'}`);
    if (response.status == 200) {
      const data = await response.json(); 
      let newList = []
      newList = data.events
      .map(el => 
        (el?.geo_position?.includes('/') || 
         !el?.geo_position?.includes('{') || 
         !el?.geo_position?.includes('}') || 
         el?.geo_position == 'Без данных' || 
         el?.geo_position == 'Нет данных') ? 
         
         ({...el, geo_position: null}) : el);
      dispatch({type: SET_EVENTS, payload: {num_events: data.num_events, events: newList}});
    } else {
      createNotification("error", "Ошибка при получении событий")
      dispatch({type: SET_EVENTS, payload: []});
    }
    dispatch({ type: SET_EVENTS_LOADING, payload: false });
  };

export const newEvent =
  (data, date) => async (dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    if (1) {
      const response = await fetch(`https://phb.nintegra.ru/api/event`, {
          method: "POST",
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },  
          body: JSON.stringify(data),
      });

      if (response.ok) {
        dispatch(getEvents(date));
        createNotification("success", "Событие добавлено")
      } else {
        createNotification("error", "Событие не добавлено")
      }
    }
    dispatch({ type: SET_EVENTS_LOADING, payload: false });
  };

  export const  editNewEvent =
  (id, data, date = {from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')}) => async (dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    if (1) {
      const response = await fetch(`https://phb.nintegra.ru/api/event/${id}`, {
          method: "POST",
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },  
          body: JSON.stringify(data),
      });

      if (response.ok) {
        createNotification("success", "Событие изменено")
        date == 'self' ? dispatch(getNewEventById(id)) : dispatch(getEvents(date))
      } else {
        createNotification("error", "Событие не изменено")
        date == 'self' ? dispatch(getNewEventById(id)) : dispatch(getEvents(date))
      }
    }
    dispatch({ type: SET_EVENTS_LOADING, payload: false });
  };

  export const getNewEventById = (id) => async(dispatch, getState) => {
    const response = await fetch(`https://phb.nintegra.ru/api/event/${id}`);
    const data = await response.json(); 
    dispatch({type: SET_EVENTS_BY_ID, payload: data});
  };

  export const deleteEvent = (id, date) => async (dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    if (getState().auth.token) {
      const response = await fetch(`https://phb.nintegra.ru/api/event/${id}`, {
        method: "DELETE",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }, 
      });
      if (response.ok) {
        dispatch(getEvents(date));
        createNotification("success", "Событие удалено")
      } else {
        createNotification("error", "Событие не удалено")
      }
    }
    dispatch({ type: SET_EVENTS_LOADING, payload: false });
  };

  export const getEventsNews = (id) => async(dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/news`);
    const data = await response.json(); 
    dispatch({type: SET_EVENTS_NEWS, payload: data});
    dispatch(getNewEventById(id));
    dispatch({ type: SET_EVENTS_LOADING, payload: false });
  };

  export const getPdfById = (id, form_id) => async(dispatch, getState) => {
    const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/form${form_id}`);
    const data = await response.json(); 
    dispatch({type: SET_PDF_BY_ID, payload: {...data, forma: form_id}});
  };

  export const uploadFile = (id, data) => async (dispatch, getState) => {
  
    const fd = new FormData()
    fd.append('file', data)
  
    if (1) {
        const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/file`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно добавлен');
          dispatch(getNewEventById(id));
        } else {
          createNotification('error', 'Файл не добавлен');
        }
    }
  }

  export const uploadPhoto = (id, data) => async (dispatch, getState) => {
    const fd = new FormData()
    fd.append('file', data)
  
    if (1) {
        const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/image`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Фото успешно добавлено');
          dispatch(getNewEventById(id));
        } else {
          createNotification('error', 'Фото не добавлено');
        }
    }
  }


  export const deleteFile = (id, file_id) => async (dispatch, getState) => {
    if (getState().auth.token) {
      const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/file/${file_id}`, {
        method: "DELETE",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }, 
      });
      if (response.ok) {
        dispatch(getNewEventById(id));
        createNotification("success", "Файл удален")
      } else {
        createNotification("error", "Файл не удален")
      }
    }
  };

  export const deletePhoto = (id, file_id) => async (dispatch, getState) => {
    if (getState().auth.token) {
      const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/image/${file_id}`, {
        method: "DELETE",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }, 
      });
      if (response.ok) {
        dispatch(getNewEventById(id));
        createNotification("success", "Фото удалено")
      } else {
        createNotification("error", "Фото не удалено")
      }
    }
  };

  export const getEventsAnswer =
  (id, text, ai, isContext = true) => async (dispatch, getState) => {
    dispatch({ type: SET_ANSWER_LOADING, payload: true });
    if (1) {
      const response = await fetch(`https://phb.nintegra.ru/api/event/${id}/askmore?gpt=${ai}&context=${isContext}&background=${true}`, {
          method: "POST",
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },  
          body: JSON.stringify({question: text}),
      });

      const data = await response.json(); 
      if (response.status == '200') {
        dispatch({type: SET_ANSWER, payload: data.id});
      } else if (data.status == 'ERROR') {
        createNotification("error", "Ошибка");
      }
    }
    dispatch({ type: SET_ANSWER_LOADING, payload: false });
  };

  export const getAskMore = (event_id, id) => async(dispatch, getState) => {
    dispatch({ type: SET_EVENTS_ASK_LOADING, payload: true });
    const response = await fetch(`https://phb.nintegra.ru/api/event/${event_id}/askmore/${id}`);
    const data = await response.json(); 
    dispatch({type: SET_EVENTS_ASK, payload: data});
    dispatch({ type: SET_EVENTS_ASK_LOADING, payload: false });
  };