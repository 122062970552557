import React from 'react'
import styles from './NotFound.module.css'
import {GiAutoRepair} from "react-icons/gi";
const NotFound = () => {
  return (
    <div className={styles.wrapper}>
        <p>Страница находится в разработке</p>
        <i><GiAutoRepair/></i>
        </div>
  )
}

export default NotFound