import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getMessageList, deleteMessage, updateMessage, createMessage } from '../../actions/messages'
import { useEffect } from 'react'
import Li from '../../components/Li'
import Loader from '../../components/Loader/Loader'
import Modal from 'react-responsive-modal'
import { useState } from 'react'
import { useFormik } from 'formik'
import Button from '../../components/Button'
import { Input, Select, Textarea } from '../../components/ModalInputs'
import { IoMdClose } from "react-icons/io";
import * as Yup from 'yup'
import { getOpoList } from '../../actions/opos'
import { IoIosAdd, IoIosClose } from "react-icons/io";
import moment from 'moment'


const validationSchema = Yup.object({
  // name: Yup.string().required('обязательное поле'),
  // code: Yup.string().required('обязательное поле'),
})

const initialFormValues = {
  background: null,
  cause: null,
  opo_id: null,
  event_start: null,
  geo_text: null,
  involved_agencies: null,
  source: null
}

export default function Messages () {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.messages)
  const { list: opos } = useSelector(state => state.opos)
  
  const [selectedMessage, setSelectedMessage] = useState(null)
  
  useEffect(() => {
    dispatch(getMessageList())
    !opos?.length && dispatch(getOpoList())
  }, [])
  
  const renderLi = (message) => {
    const {id, background} = message
    return (
      <Li key={id} onClick={() => setSelectedMessage(message)}>
        <div className={styles.firstLiBlock}>
          <div>{background}</div>
        </div>
        <IoMdClose color='red' size={24} onClick={deleteDiseaseHandler(id)}/>
      </Li>
    )
  }

  const deleteDiseaseHandler = (id) => (e) => {
    e.stopPropagation()
    dispatch(deleteMessage(id))
  }

  const closeModal = () => {
    setSelectedMessage(null)
  }
  
  return (
    <div className={styles.wrapper}>
      <Button onClick={() => setSelectedMessage({})}>Добавить сообщение</Button>
      <h2>Сообщения</h2>
      {loading
        ? <Loader top='30%' left='46.5%'/>
        : !!list.length && <ul>{list.map(renderLi)}</ul>
      }
      <MessagesModal
        open={!!selectedMessage}
        onClose={closeModal}
        disease={selectedMessage}
        addMode={!selectedMessage?.id}
      />
    </div>
  )
}


const MessagesModal = ({ open, onClose, disease, addMode }) => {
  
  const dispatch = useDispatch()
  const opos = useSelector(state => state.opos.list)

  const [editAgency, setEditAgency] = useState(null)

  useEffect(() => {
    const event_start = moment(disease?.event_start).format('YYYY-MM-DD HH:mm')
    open && setValues(addMode ? initialFormValues : {...disease, event_start})
  }, [open])
  
  const submitForm = ({id, ...values}) => {
    const event_start = values.event_start ? moment(values.event_start).utc().format('YYYY-MM-DD HH:mm') : null
    const data = {...values, event_start}
    id ? dispatch(updateMessage(id, data)) : dispatch(createMessage(data))
    onClose()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    handleBlur,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm
  })
  
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const handleAddAgency = () => {
    const newList = values.involved_agencies ? [...values.involved_agencies] : []
    newList.push('Служба')
    setFieldValue('involved_agencies', newList)
  }
  
  const handleDeleteAgency = (index) => () => {
    const newList = [...values.involved_agencies]
    newList.splice(index, 1)
    setFieldValue('involved_agencies', newList)
  }
  
  const onAgencyItemChange = (index) => (e) => {
    const {value} = e.target
    const newList = [...values.involved_agencies]
    newList.splice(index, 1, value)
    setFieldValue('involved_agencies', newList)
  }

  return (
    <Modal
      open={open}
      classNames={{ modal: styles.modal }}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >

      <h2>{addMode ? 'Добавление сообщения' : 'Редактирование сообщения'}</h2>

      <Input
        type='datetime-local'
        style={{width: '100%'}}
        label='Время и дата происшествия'
        value={values.event_start}
        onChange={handleChange}
        onBlur={handleBlur}
        name='event_start'
        error={error('event_start')}
      />

      <Input
        style={{width: '100%'}}
        value={values.geo_text}
        onChange={handleChange}
        onBlur={handleBlur}
        name='geo_text'
        label='Место происшествия'
        error={error('geo_text')}
      />

      <Select
        style={{width: '100%'}}
        value={values.opo_id}
        onChange={handleChange}
        onBlur={handleBlur}
        name='opo_id'
        label='Опасный объект'
        error={error('opo_id')}
      >
        <option value=''>Выберите организацию</option>
        {opos.map(({id, opo_name}) => <option key={id} value={id}>{opo_name}</option>)}
      </Select>

      <Textarea
        style={{width: '100%'}}
        value={values.cause}
        onChange={handleChange}
        onBlur={handleBlur}
        name='cause'
        label='Причина ЧС'
        error={error('cause')}
      />

      <Input
        style={{width: '100%'}}
        value={values.source}
        onChange={handleChange}
        onBlur={handleBlur}
        name='source'
        label='От кого получено сообщение'
        error={error('source')}
      />
      


      <div className={styles.agencyBlock}>
        <p className="news__label news__label_mt">Службы:</p>
        <div className={styles.categoriesBlock}>
          {values.involved_agencies?.map((agency, ind) => {
              const divWidth = agency.split("").length * 9.1 + 30;
              return (
                <div
                  key={ind}
                  onClick={() => setEditAgency(ind)}
                  className={styles.catItem}
                  style={{ width: `${divWidth}px` }}
                >
                  {editAgency === ind ? (
                      <input
                        value={agency}
                        onChange={onAgencyItemChange(ind)}
                        onBlur={() => setEditAgency(null)}
                        name="agency"
                        autoFocus
                      />
                    ) : agency
                  }
                  <IoIosClose onClick={handleDeleteAgency(ind)} />
                </div>
              );
            })}
          <div onClick={handleAddAgency}>
            <IoIosAdd size={20} color="white" />
          </div>
        </div>
      </div>

      <Textarea
        style={{width: '100%'}}
        value={values.background}
        onChange={handleChange}
        onBlur={handleBlur}
        name='background'
        label='Справочные сведения'
        error={error('background')}
      />

      <div className={styles.buttonsBlock}>
        <Button onClick={handleSubmit}>Сохранить</Button>
        <Button onClick={onClose}>Закрыть</Button>
      </div>

    </Modal>
  )
}