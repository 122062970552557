import React from 'react'
import styles from './styles.module.css'
export default function Ticker ({
  data = [],
}) {
  
  const textLength = data?.reduce((acc, text) => acc + text.length, 0) || 0
  const animationDuration = `${textLength / 15}s`


  return (
    <div className={styles.tickerWrap}>
      <div
        className={styles.ticker}
        style={{ animationDuration }}
      >
        {data.map(text => <div className={styles.ticker__item}>{text}</div>)}
      </div>
    </div>
  )
}
