export const buildDefaultHeaders = getState => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`
        }
    }
}

export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = []
        .slice
        .call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    const base64Flag = 'data:image/jpeg;base64,';
    const imageStr = window.btoa(binary);

    return base64Flag + imageStr;
};
