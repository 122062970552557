import React from 'react'


export default function ChemicalSituation () {

    return (
      <div style={{display:"flex", flexDirection:"column"}}>
      <img src='https://phb.nintegra.ru/analize/%D0%A5%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-33-22.png'/>
      <img src='https://phb.nintegra.ru/analize/%D0%A5%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-34-00.png'/>
      <img src='https://phb.nintegra.ru/analize/%D0%A5%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-34-07.png'/>
      <img src='https://phb.nintegra.ru/analize/%D0%A5%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-34-11.png'/>
      </div>
      )
  
}