import {BACKEND_URL_PREFIX, BACKEND_DOMAIN, version} from "../assets/const";
import {buildDefaultHeaders} from "./helper";
import moment from 'moment'
import {AUTH_COMPLETED, SET_AUTH_LOADING, SIGNED_OUT} from "./types";
import {createNotification} from "../components/Notifications/Notifications";

const TOKEN_UPDATING_INTERVAL = 190;

const getTimeToExpireToken = (getState) => {
    const {valid_until} = getState().auth;
    return moment(valid_until).diff(moment.utc()) / 1000 / 60;
};

export const checkAndUpdateToken = () => async(dispatch, getState) => {
    const timeToExpire = getTimeToExpireToken(getState);
    if (timeToExpire < TOKEN_UPDATING_INTERVAL) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/token`, buildDefaultHeaders(getState));
            if (response.ok) {
                const result = await response.json();
                if (result.error && result.error.code === 401) {
                    dispatch(signOut())
                } else {
                    dispatch({type: AUTH_COMPLETED, user: result});
                }
            } else {
                dispatch(signOut())
            }
        } catch (ERR) {
            dispatch(signOut())
        }
    }

};

export const auth = (values) => async(dispatch, getState) => {
    dispatch({type: SET_AUTH_LOADING, payload: true});
    try {
        // const { username, password } = values; const tok
        // =`${username.trim()}:${password.trim()}`; const hash =
        // window.btoa(unescape(encodeURIComponent(tok))); const Authorization = `Basic
        // ${hash}`; const response = await fetch(`${BACKEND_URL_PREFIX}/token`, {
        // headers: {     Authorization,     "Access-Control-Allow-Origin": "*",   },
        // });

        if (true) {
            // const result = await response.json();
            const result = {
                token: '1'
            }
            dispatch({type: AUTH_COMPLETED, user: result});
        } else {
            // const errorText = response.status === 401 ? "Неверное имя пользователя или
            // пароль" : "Ошибка авторизации" createNotification('error', errorText)
        }
    } catch (ERROR) {
        createNotification("error", "Сбой авторизации");
        console.log(ERROR);
    } finally {
        dispatch({type: SET_AUTH_LOADING, payload: false});
    }
};

export const signOut = () => async(dispatch) => {
    dispatch({type: SIGNED_OUT});
};
