import React, { useState } from 'react'
import styles from './styles.module.css'
import {alarmList, summary, countriesName, optionsEvent, optionsDanger, dataRu, dataUa, dataCyr, dataWorld, alarmColor} from '../../assets/const'
import Clock from '../../components/Clock/Clock';
import Slider from '../../components/Slider/Slider'
import Select from '../../components/Select/Select'

import { editNewEvent, getEvents } from '../../actions/events';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataRu from '../../assets/cast.geo.json'
import mapDataUkaine from '../../assets/cast.geoUa.json'
import mapDataSyria from '../../assets/cast.geoSyr.json'
import mapDataWorld from '../../assets/cast.geoWorld.json'
import regList from '../../assets/regList.json'
import proj4 from 'proj4';

import geoR from '../../assets/img/summary/geoRed.png'
import geoY from '../../assets/img/summary/geoYellow.png'
import geoG from '../../assets/img/summary/geoGreen.png'
import geo from '../../assets/img/summary/geo.png'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames'
import Ticker from '../../components/Ticker';

import voice from '../../assets/img/audio/voice.png'
import voiceGrey from '../../assets/img/audio/voice_grey.png'

if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}
  
const xxx = [
  {
      summary_id: 'ru',  
      summary_type: 0,
      id: 72,
      name: "Разлив сырой нефти, 1200 м2",
      place: "55.749912981873116,49.28580423098632",
    },
    {
      summary_id: 'ru',
      summary_type: 1,
      id: 72,
      name: "2 Разлив сырой нефти, 1200 м2",
      place: "75.749912981873116,35.28580423098632",
    }
];

export default function Main() {
  
    const dispatch = useDispatch();
    const events = useSelector(state => state.events.data.events);
    
    const [isShow, setIsShow] = useState(false);
    const [activeSummary, setActiveSummary] = useState([{id: 'ru', value: 0}, {id: 'world', value: 0}, {id: 'ua', value: 0}, {id: 'syr', value: 0}]);
    const [pointList, setPointList] = useState([]);
    const [position, setPosition] = useState({plotX: 0, plotY: 0, list: [], id: null, summary_id: null, summary_type: null});
    const [eventsLocal, setEventsLocal] = useState([]);
    const [fullMode, setFullMode] = useState('')
    const [firstUpdate, setFirstUpdate] = useState(true)
    const [audioState, setAudioState] = useState('paused')


    useEffect(() => {
      firstUpdate && events && events.map(event => {
          if (event?.geo_text && event.geo_position == null) {
              geo_text(event)        
          }
      })
      setFirstUpdate(false)
    }, [events])

    const geo_text = (event) => {
      fetch(`https://geocode-maps.yandex.ru/1.x?apikey=9e115931-69dc-4f36-b58c-ce53f85a6f89&geocode=${event.geo_text}&format=json`)
      .then(res => res.json()).then(data => {
          const coord = data && data?.response?.GeoObjectCollection.featureMember?.[0]?.GeoObject?.Point?.pos.split(' ');
          (coord && coord[1] && coord[0]) && dispatch(editNewEvent(event.id, {...event, geo_position: [Number(coord[1]), Number(coord[0])] || null }));
      }).finally(() => {
          dispatch(getEvents({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')}, false, true));
      })    
    }

    useEffect(() => {
      dispatch(getEvents({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')}, false, true));
    }, [])

    useEffect(() => {
        if (events) {
        const itemList = events.map(el => ({...el, geo_position: [(el?.geo_position?.split(',')[0])?.replace('{', ''), (el?.geo_position?.split(',')[1])?.replace('}', '') || null]})) 
        setEventsLocal(itemList);}
      }, [events])

    const handleReg = (region, id, plotX, plotY) => {    
      if (pointList.filter(el => el.region == region).length > 1) {
        setPosition(prev => ({...prev, id: region, plotX: plotX, plotY: plotY, 
          // list: pointList.filter(el => el.region == region)
        }))
      } else {
        // setIsPopup(id);
        setPosition({id: null, plotX: 0, plotY: 0, list: []});
      } 
    }

    let tmpArray = [];
  function itemCheck(item) {
    if (tmpArray.indexOf(item.region) === -1) {
        tmpArray.push(item.region);
        return true
    }
    return false;
  }

const handlePopupList = () => {
  setPosition({id: null, plotX: 0, plotY: 0, list: []});
}

useEffect(() => {
  const x =  xxx.map(el => (
    {
      summary_type: el.summary_type,
      summary_id: el.summary_id,
      z: null, 
      lat: +el.place.split(',')[0], 
      lon: +el.place.split(',')[1], 
      name: el.name, 
      id: el.id, 
      region: el.region_id, 
      name: el.type, } 
    ))
    // const newList = events?.map(el => ({
    //   ...el,
    //   lat: +el.geo_position.split(',')[0].replace('{', ''),
    //   lon: +el.geo_position.split(',')[1].replace('}', ''),
    // }))
  // setPointList(newList);
  setPointList(x);
}, [eventsLocal])

  const geoType = {
    0: geoR,
    1: geoG,
    2: geoY,
  }

const optionsRu = {

      title: {
          text: ""
      },
      
      mapNavigation: {
        // enabled: true,
      },
  
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        backgroundColor: 'transparent'
      },
      series: [
              {
                name: "Карта",
                mapData: mapDataRu,
                // borderColor: 'rgb(0, 0, 0)',
                // nullColor: '#EAD2D2',
                showInLegend: false,
                type: 'map',
                data: dataRu,
                // color: '#e7b3b3f0',
              },
              {
                marker: {
                  lineWidth: 0,
                  lineColor: '',
                  symbol: `url(${geo})`,
                  radius: 20,
                  padding: 0
                },
                dataLabels: {
                  verticalAlign: 'top',
                  shadow: false,
                  style:{
                    // textOutline: 'none',
                    // color: 'red',
                    fontSize: '12px',
                    }
                },
                type: "mappoint",
                name: "",
                
                showInLegend: false,
                // enabled: false,
                // useHTML:false,
                // padding: 0,
                
                
                // color: "brown",
  
                data: pointList,
                tooltip: {
                  // shared: true,
                  headerFormat: "Узнать",
                  pointFormat: '<b>{point.name}</b><br>',
                  enabled: false,
                },
                cursor: "pointer",
                point: {
                  events: {
                    click: function() {
                      // handleReg(this.region, this.id, this.plotX, this.plotY);
                      const newList = [...activeSummary].map(el => el.id == this.summary_id ? {id: this.summary_id, value: this.summary_type} : el)
                      setActiveSummary(newList);
                      setIsShow(true);
                    }
                  },
                }
              }
              ]
  }
const optionsUa = {

    title: {
        text: ""
    },
    
    mapNavigation: {
      // enabled: true,
    },

    credits: {
      enabled: false
    },
    chart: {
      animation: false,
      backgroundColor: 'transparent'
    },
    series: [
            {
              name: "Карта",
              mapData: mapDataUkaine,
            //   borderColor: 'rgb(0, 0, 0)',
              // nullColor: '#EAD2D2',
              showInLegend: false,
              type: 'map',
              data: dataUa,
              // color: '#e7b3b3f0',
            },
            {
              marker: {
                lineWidth: 0,
                lineColor: '',
                symbol: `url(${geo})`,
                radius: 20,
                padding: 0
              },
              dataLabels: {
                verticalAlign: 'top',
                shadow: false,
                style:{
                  // textOutline: 'none',
                  // color: 'red',
                  fontSize: '12px',
                  }
              },
              type: "mappoint",
              name: "",
              
              showInLegend: false,
              // enabled: true,
              // useHTML:false,
              // padding: 0,
              tooltip: {
                // headerFormat: "Узнать",
                pointFormat: '<b>{point.name}</b><br>',
                enabled: false,
              },
              
              // color: "brown",

              // data: heroesList,

              cursor: "pointer",
              point: {
                events: {
                  click: function() {
                    handleReg(this.region, this.id, this.plotX, this.plotY);
                }
                },
              }
            }
            ]
  }
  
const optionsSyr = {

    title: {
        text: ""
    },
    
    mapNavigation: {
      // enabled: true,
    },

    credits: {
      enabled: false
    },
    chart: {
      animation: false,
      backgroundColor: 'transparent'
    },
    series: [
            {
              name: "Карта",
              mapData: mapDataSyria,
            //   borderColor: 'rgb(0, 0, 0)',
              // nullColor: '#EAD2D2',
              showInLegend: false,
              type: 'map',
              data: dataCyr,
              // color: '#e7b3b3f0',
            },
            {
              marker: {
                lineWidth: 0,
                lineColor: '',
                symbol: `url(${geo})`,
                radius: 20,
                padding: 0
              },
              dataLabels: {
                verticalAlign: 'top',
                shadow: false,
                style:{
                  // textOutline: 'none',
                  // color: 'red',
                  fontSize: '12px',
                  }
              },
              type: "mappoint",
              name: "",
              
              showInLegend: false,
              // enabled: true,
              // useHTML:false,
              // padding: 0,
              tooltip: {
                // headerFormat: "Узнать",
                pointFormat: '<b>{point.name}</b><br>',
                enabled: false,
              },
              
              // color: "brown",

              // data: heroesList,

              cursor: "pointer",
              point: {
                events: {
                  click: function() {
                    handleReg(this.region, this.id, this.plotX, this.plotY);
                }
                },
              }
            }
            ]
  }

const optionsWorld = {

  title: {
      text: ""
  },
  
  mapNavigation: {
    // enabled: true,
  },

  credits: {
    enabled: false
  },
  chart: {
    animation: false,
    backgroundColor: 'transparent'
  },
  series: [
          {
            name: "Карта",
            mapData: mapDataWorld,
          //   borderColor: 'rgb(0, 0, 0)',
            // nullColor: '#EAD2D2',
            showInLegend: false,
            type: 'map',
            data: dataWorld,
            // color: '#e7b3b3f0',
          },
          {
            marker: {
              lineWidth: 0,
              lineColor: '',
              symbol: `url(${geo})`,
              radius: 20,
              padding: 0
            },
            dataLabels: {
              verticalAlign: 'top',
              shadow: false,
              style:{
                // textOutline: 'none',
                // color: 'red',
                fontSize: '12px',
                }
            },
            type: "mappoint",
            name: "",
            
            showInLegend: false,
            // enabled: true,
            // useHTML:false,
            // padding: 0,
            tooltip: {
              // headerFormat: "Узнать",
              pointFormat: '<b>{point.name}</b><br>',
              enabled: false,
            },
            
            // color: "brown",

            // data: heroesList,

            cursor: "pointer",
            point: {
              events: {
                click: function() {
                  handleReg(this.region, this.id, this.plotX, this.plotY);
              }
              },
            }
          }
          ]
  }

  const mapType = {
    'ru': optionsRu,
    'ua': optionsUa,
    'syr': optionsSyr,
    'world': optionsWorld
  };

  const renderCountryBlock = (country, index) => {
    const eventsList = events?.filter(event => event.location == country.location) || []
    return (
      <CountryBlock
        key={index}
        data={country}
        events={eventsList}
        mapType={mapType}
        activeSummary={activeSummary}
        isShow={isShow}
        fullMode={country.type === fullMode}
        fullModeClickHandler={fullModeClickHandler}
      />
    )
  }

  const fullModeClickHandler = (type) => () => {
    const newTypeValue = type === fullMode ? '' : type
    setFullMode(newTypeValue)
    summary.forEach(country => {
      const innerBlock = document.getElementById(`innerBlock${country.type}`)
      innerBlock.style.zIndex = type === country.type ? 1 : 0
    })
  }

  const tickerData = events?.map(event => event.title)
  
  
  const playPauseAudio = async () => {
    const audio = document.getElementById('audio');
    const audioPaused = audio.paused
    if (audioPaused) {
      await audio.play();
      setAudioState('playing');
    } else {
      await audio.pause();
      setAudioState('paused');
    }
  }

  return (
        <>
          <Ticker data={tickerData}/>
          <div className={styles.wrapper}>
              {
                position.plotX > 0 && position.plotY > 0 &&
                  <div 
                    className={styles.popupListWrap}
                    onClick={() => setPosition({id: null, plotX: 0, plotY: 0, list: []})}
                  >
                    <div 
                    className={styles.popupList}
                    style={{position:'absolute', zIndex: 111, top:`${position?.plotY}px`, left:`${position.plotX}px`}}>
                      <div>Регион: <bold>{regList.find(el => el.id == position.id)?.regionName} {regList.find(el => el.id == position.id)?.federalUnit}</bold></div>
                      {position.list.map (el => 
                        <span onClick={() => handlePopupList(el)}>{el.name}</span>  
                        )}
                    </div>
                  </div>
              }
              <div className={styles.wrapper_clock}>
                  <div className={styles.wrapperInfo}>
                      <Clock/>
                      <Select options={optionsEvent} name="события"/>
                      <Select options={optionsDanger} name="уровень опасности"/>
                      <div className={styles.audioBlock}>
                        <img src={audioState === 'paused' ? voiceGrey : voice} alt='o_O' onClick={playPauseAudio} />
                        <audio id='audio' >
                          <source src='https://phb.nintegra.ru/api/readtext' type='audio/mpeg' />
                        </audio>
                      </div>
                  </div>
                  <div className={styles.wrapperSummary}>
                      {summary.map(renderCountryBlock)}
                  </div>
              </div>
          </div>
        </>
    )
}


const CountryBlock = ({
  data,
  events,
  isShow,
  mapType,
  activeSummary,
  fullMode,
  fullModeClickHandler
}) => {
  
  const [actualEvents, setActualEvents] = useState([])
  const [eventType, setEventType] = useState('Все')
  
  const eventCount = events?.length ? `${events.length} события рхб` : 'Нет событий'
  
  useEffect(() => {
    const newActualEvents = events.filter(({incident_type}) => eventType === 'Все' || incident_type === eventType)
    !fullMode && newActualEvents.splice(3)
    setActualEvents(newActualEvents)
  }, [fullMode, eventType, events])
  

  const renderAlarmType = (alarmType, index) => {
    const {id, img, type, title} = alarmType
    // const isAllActive = type === 'Все' && !!events.length
    const isActive = events.find(({incident_type}) => incident_type === type)
    const isSelected = eventType === type && actualEvents.length
    const background = isActive ? alarmColor[id] :  '#c9c9c9'
    const classNames = cn(styles.alarmItem, {
      [styles.alarmItem__active]: isActive,
      [styles.alarmItem__selected]: isSelected
    })
    // isActive && setEventType(type)
    return (
      <div 
        key={index} 
        style={{ background }}
        className={classNames}
        onClick={() =>  isActive && setEventType(type)}
      >
        {img ? <img src={img} alt='o_O' className={styles.alartText}/> : <span className={styles.alarmItem__All}>ВСЕ</span>}
        <span className={styles.alarmItem__hint}>{title}</span>
      </div>
    )
  }

  return (
    <div className={styles.summaryBlock}>
    <div
      className={cn(styles.summaryInnerBlock, { [styles.innerBlockActive]: fullMode })}
      id={`innerBlock${data.type}`}
    >
      <div className={styles.summaryBlock__left}>
            <div className={styles.summary_header}>
                <span className={styles.summary_header_title}>{countriesName[data.id]}</span>
                <span className={styles.summary_header_count}>{eventCount} </span>
            
                <div className={styles.alarmList}>
                  {alarmList.map(renderAlarmType)}
                </div> 
            </div>
            
            <div className={styles.map}>
                
                {<HighchartsReact
                containerProps={{ style: { backgroundColor: 'transparent', height: '100%' } }}
                options={mapType[data.type]}
                highcharts = { Highcharts }
                constructorType={'mapChart'}/>}
            </div>

              
            <div className={styles.summary_slider}>
                {/* {el.value ? el.summary[activeSummary.find(_el=> _el.id === el.id).value].imgs.map((el, i) => <img key={i} src={el} className={styles.summaryImg}/>) : <></>} */}
                
                {isShow ?
                <Slider data={data.value ? data.summary[activeSummary.find(_el=> _el.id === data.type).value].imgs : []}/>
                : <></>
                }
            </div>
        </div>

        <div className={styles.summaryBlock__right}>
            { !isShow
              ? actualEvents.map(event =>
                  <Link to={`/events/${event.id}`} key={event.id}>
                    <span className={styles.summary_title}>
                      {event.title}
                    </span>
                  </Link>
                )
              : <>
                  <span className={styles.summary_title}>{data.value ? data.summary[0].title : <></>}</span>
                  <div className={styles.summary_text}>
                    {data.value ? data.summary[activeSummary.find(_el=> _el.id === data.type).value].text.map(el => <span key={el}>{el}</span>) : <></>}
                  </div>
                </>
            }
        </div>

        <button
          className={styles.summaryBtn}
          onClick={fullModeClickHandler(data.type)}
        >
          {fullMode ? 'Свернуть' : 'Смотреть все'}
        </button>

      </div>
    </div>
  )
}