import * as types from "../actions/types";

const initialState = {
    event: {},
    data: [],
    loading: false,
    events_news: [],
    pdf_list: [],
    answer_id: null,
    ask: {},
    answer_loading: false
};

export default function events(state = initialState, action) {
    switch (action.type) {
        case types.SET_EVENTS:
            return {
                ...state,
                data: action.payload
            };
    case types.SET_EVENTS_BY_ID:
    return {
        ...state,
        event: action.payload
    };
    case types.SET_EVENTS_NEWS:
    return {
        ...state,
        events_news: action.payload
    };
    case types.SET_EVENTS_LOADING:
        return {
            ...state,
            loading: action.payload
        };
    case types.SET_PDF_BY_ID:
        return {
            ...state,
            pdf_list: action.payload
        };
    case types.SET_ANSWER:
        return {
            ...state,
            answer_id: action.payload
        };
    case types.SET_EVENTS_ASK:
        return {
            ...state,
            ask: action.payload
        };
    case types.SET_ANSWER_LOADING:
        return {
            ...state,
            answer_loading: action.payload
        };
        default:
            return state;
    }
}