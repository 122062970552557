import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Table ({children, className, ...props}) {
  
  const classNames = cn([styles.table, className])

  return (
    <div className={classNames} {...props}>
        <table>
            {children}
        </table>
    </div>
  )
}
