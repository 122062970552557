import {BsFillTelephoneFill} from "react-icons/bs";
import {TfiEmail, TfiWorld} from "react-icons/tfi";

import _47png from './img/test/47.png'
import _16png from './img/test/16.png'
import _28png from './img/test/28.png'
import _31png from './img/test/31.png'

import alarm_1 from './img/layout/alarm/1.png'
import alarm_2 from './img/layout/alarm/2.png'
import alarm_3 from './img/layout/alarm/3.png'
import alarm_4 from './img/layout/alarm/4.png'

import ru_1 from './img/summary/ru_1.png'
import ru_2 from './img/summary/ru_2.png'
import ru_3 from './img/summary/ru_3.png'
import ru_4 from './img/summary/ru_4.png'

import wrld_1 from './img/summary/wrld_1.png'
import wrld_2 from './img/summary/wrld_1.png'
import wrld_3 from './img/summary/wrld_1.png'
import wrld_4 from './img/summary/wrld_1.png'

export const version = '13.12.1' //версия приложения

export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN

export const links = [
    {
        icon: <BsFillTelephoneFill/>,
        link: '+7(495) 699-00-00',
        name: '+7(495) 699-00-00',
        type: 'tel',
        blank: false
    }, 
    {
        icon: <TfiEmail/>,
        link: 'rhbz@mil.ru',
        name: 'rhbz@mil.ru',
        type: 'mailto',
        blank: false
    }, 
    {
        icon: <TfiWorld/>,
        link: '/',
        name: 'Войска РХБЗ ВС РФ',
        type: 'link',
        blank: false
    }, {
        icon: <TfiWorld/>,
        link: 'https://stat.mil.ru',
        name: 'Минобороны России',
        type: 'link',
        blank: true
    },
    {
        icon: '',
        link: 'https://www.nintegra.ru',
        name: 'Разработано: © 2023 ООО “Нинтегра”',
        type: 'link',
        blank: true
    }
]

export const mainMenu = [
    {
        name: 'Модуль ОП',
        link: '#',
        innerLinks: [
            {
                name: 'Новости',
                link: 'news'
            },{
                name: 'События',
                link: 'events'
            },{
                name: 'Сообщения РХБ',
                link: 'messages'
            }
        ]
    }, {
        name: 'Справочники',
        link: 'metodica',
        innerLinks: [
            {
                name: 'Объекты ОПО',
                link: 'opos'
            }, {
                name: 'Опасные вещества',
                link: 'dm'
            }, {
                name: 'Опасные заболевания',
                link: 'diseases'
            }
        ]
    }, {
        name: 'Гис',
        link: 'gis',
        innerLinks: []
    }, {
        // name: 'Анализ и прогноз',
        // link: 'Setting',
        // innerLinks: [
        //     {
        //         name: 'Прогноз по событиям',
        //         link: 'notFound'
        //     }, {
        //         name: 'План действий по событию',
        //         link: 'notFound'
        //     }, {
        //         name: 'Статистика по событию',
        //         link: 'notFound'
        //     }, {
        //         name: 'Хронология события',
        //         link: 'notFound'
        //     }
        // ]
        name: 'Анализ и прогноз',
        link: 'Setting',
        innerLinks: [
            {
                name: 'Анализ химической обстановки  ',
                link: 'chemicalSituation'
            }, {
                name: 'Анализ радиационной обстановки',
                link: 'radiationSituation'
            }, {
                name: 'Анализ биологической обстановки',
                link: 'biologicalSituation'
            }, 
        ]
    }, {
        name: 'Отчетность',
        link: 'Setting',
        innerLinks: [
            {
                name: 'Справка-доклад по событию',
                link: 'notFound'
            },{
                name: 'Информационная справка по опасному веществу',
                link: 'notFound'
            },{
                name: 'Информационная справка по объекту',
                link: 'notFound'
            },{
                name: 'Информационная сводка за период',
                link: 'notFound'
            },{
                name: 'Информация о метеорологической обстановке',
                link: 'meteo'
            },{
                name: 'Отчет о РХБ обстановке за период',
                link: 'notFound'
            },{
                name: 'Обстановка на дату',
                link: ''
            },{
                name: 'Рекомендации ИИ по событию',
                link: 'notFound'
            },{
                name: 'Экспорт в ГИС',
                link: 'notFound'
            }
        ]
    }, {
        name: 'Настройки',
        link: 'Setting',
        innerLinks: [
            {
                name: 'Общие настройки',
                link: 'generalSettings'
            }, {
                name: 'Роли',
                link: 'roles'
            }, {
                name: 'Доступы',
                link: 'Access'
            }, {
                name: 'Пользователи',
                link: 'users'
            }, {
                name: 'Журнал безопасности',
                link: 'SecurityLog'
            }, {
                name: 'Резервное копирование',
                link: 'backup'
            }, {
                name: 'Источники информации',
                link: 'sources'
            }
        ]
    }
]

export const alarmColor = {
    0: '#ffcb2e',
    1: '#f62828',
    2: '#22da04',
    3: '#ab59c4',
    4: '#5992c4'
  };

  export const risk = [
    {
        id: 0,
        title: 'Авария'
    },
    {
        id: 1,
        title: 'Радиационный'
    },
    {
        id: 2,
        title: 'Химический'
    },
    {
        id: 3,
        title: 'Биологический'
    }
]

  export const alarmList = [
    {
        id: 0,
        img: alarm_1,
        isActive: true,
        type: 'Радиационный',
        title: 'Радиационная опасность'
    },
    {
        id: 1,
        img: alarm_2,
        isActive: true,
        type: 'Химический',
        title: 'Химическая опасность'
    },
    {
        id: 2,
        img: alarm_3,
        isActive: true,
        type: 'Биологический',
        title: 'Биологическая опасность'
    },
    {
        id: 3,
        img: alarm_4,
        isActive: true,
        type: 'Авария',
        title: 'Авария'
    },
    // {
    //     id: 4,
    //     img: '',
    //     isActive: true,
    //     type: 'Все',
    //     title: 'Все'
    // },
]

export const alarmList2 = [
    {
        id: 0,
        img: alarm_1,
        isActive: false,
        title: 'Радиационная опасность'
    },
    {
        id: 1,
        img: alarm_2,
        isActive: true,
        title: 'Химическая опасность'
    },
    {
        id: 2,
        img: alarm_3,
        isActive: false,
        title: 'Биологическая опасность'
    },
    {
        id: 3,
        img: '',
        isActive: false,
        title: 'Все'
    },
]

export const alarmList3 = [
    {
        id: 0,
        img: alarm_1,
        isActive: false,
        title: 'Радиационная опасность'
    },
    {
        id: 1,
        img: alarm_2,
        isActive: false,
        title: 'Химическая опасность'
    },
    {
        id: 2,
        img: alarm_3,
        isActive: false,
        title: 'Биологическая опасность'
    },
    {
        id: 3,
        img: '',
        isActive: false,
        title: 'Все'
    },
]

export const summary = [
    {
        id: 0,
        type: 'ru',
        location: 'Россия',
        value: 2,
        summary: [{
            id: 1,
            title: 'Разлив сырой нефти, 1200 м2',
            text: [
                'Кировская область, Нововятский район, г. Вятские Поляны, ул. Промышленная ,14',
                'НПЗ “ВятскНефтьСинтез”',
                'В зоне возможного распространения 2 школы, 3 детских сада, 1 поликлиника.',
                '17 402 человека.',
                'Первоочередные меры: отсыпка абсорбирующим реагентом, проливка пеной всей зоны разлива, эвакуация сотрудников предприятия, оповещение администраций социально значимых объектов.'
            ],
            imgs: [ru_1, ru_2, ru_3, ru_4, ru_1, ru_2, ru_3, ru_4],
            alarmList: alarmList2
        },
        {
            id: 2,
            title: '2Разлив сырой нефти, 1200 м2',
            text: [
                '2Кировская область, Нововятский район, г. Вятские Поляны, ул. Промышленная ,14',
                '2НПЗ “ВятскНефтьСинтез”',
                '2В зоне возможного распространения 2 школы, 3 детских сада, 1 поликлиника.',
                '217 402 человека.',
                '2Первоочередные меры: отсыпка абсорбирующим реагентом, проливка пеной всей зоны разлива, эвакуация сотрудников предприятия, оповещение администраций социально значимых объектов.'
            ],
            imgs: [ru_1, ru_2, ru_3, ru_4],
            alarmList: alarmList
        }
    ]
    },
    {
        id: 1,
        type: 'world',
        location: 'Мир',
        value: 11,
        summary: [{
            id: 1,
            title: 'Утечка аммиака на химическом предприятии',
            text: [
                'Бангладеш. House № NE (J) 9, Road 79, Gulshan-2, 1212, Dhaka, Bangladesh',
                'Bangladesh Chemical Industries Corporation',
                'В зоне возможного распространения 2 школы, 3 детских сада, 1 поликлиника.',
                'В зоне возможного распространения 7 больниц.',
                '144 511 человек.',
                'Первоочередные меры: оповещение населения, находитесь на открытом воздухе, то покидайте опасную зону в направлении, перпендикулярном ветру.'
            ],
            imgs: [wrld_1, wrld_2, wrld_3, wrld_4, wrld_1, wrld_2, wrld_3, wrld_4],
            alarmList: alarmList
        },
        {
            id: 2,
            title: '2Утечка аммиака на химическом предприятии',
            text: [
                '2Бангладеш. House № NE (J) 9, Road 79, Gulshan-2, 1212, Dhaka, Bangladesh',
                '2Bangladesh Chemical Industries Corporation',
                '2В зоне возможного распространения 2 школы, 3 детских сада, 1 поликлиника.',
                '2В зоне возможного распространения 7 больниц.',
                '2144 511 человек.',
                '2Первоочередные меры: оповещение населения, находитесь на открытом воздухе, то покидайте опасную зону в направлении, перпендикулярном ветру.'
            ],
            imgs: [wrld_1, wrld_2, wrld_3, wrld_4, wrld_1, wrld_2, wrld_3, wrld_4],
            alarmList: alarmList
        }]
        
    },
    {
        id: 2,
        type: 'ua',
        location: 'Украина',
        value: 0,
        summary: [{}]
    },
    {
        id: 3,
        type: 'syr',
        location: 'Сирия',
        value: 0,
        summary: [{}]
    },
]

export const countriesName = {
    0: 'Российская Федерация',
    1: 'Мир',
    2: 'Украина',
    3: 'Сирия',
} 

export const optionsEvent = [
    {
        name: 'все',
        value: ''
    },
    {
        name: 'новые',
        value: '1'
    },
    {
        name: 'длящиеся',
        value: '2'
    }
] 
export const optionsDanger = [
    {
        name: 'все',
        value: ''
    },
    {
        name: '1 уровень',
        value: '1'
    },
    {
        name: '2 уровень',
        value: '2'
    },
    {
        name: '3 уровень',
        value: '3'
    },
    {
        name: '4 уровень',
        value: '4'
    },
]

export const dataRu = [ ["ru-hers", ''], ["ru-za", ''], ["ru-dn", ''], ["ru-lh", ''], ["ru-sc", ''], ["ru-2509", ''], ["ru-2485", ''], ["ru-ck", ''], ["ru-ar", ''], ["ru-nn", ''], ["ru-yn", ''], ["ru-ky", ''], ["ru-sk", ''], ["ru-kh", ''], ["ru-sl", ''], ["ru-ka", ''], ["ru-kt", ''], ["ru-2510", ''], ["ru-rz", ''], ["ru-sa", ''], ["ru-ul", ''], ["ru-om", ''], ["ru-ns", ''], ["ru-mm", ''], ["ru-ln", ''], ["ru-sp", ''], ["ru-ki", ''], ["ru-kc", ''], ["ru-in", ''], ["ru-kb", ''], ["ru-no", ''], ["ru-st", ''], ["ru-sm", ''], ["ru-ps", ''], ["ru-tv", ''], ["ru-vo", ''], ["ru-iv", ''], ["ru-ys", ''], ["ru-kg", ''], ["ru-br", ''], ["ru-ks", ''], ["ru-lp", ''], ["ru-ms", ''], ["ru-ol", ''], ["ru-nz", ''], ["ru-pz", ''], ["ru-vl", ''], ["ru-vr", ''], ["ru-ko", ''], ["ru-sv", ''], ["ru-bk", ''], ["ru-ud", ''], ["ru-mr", ''], ["ru-cv", ''], ["ru-cl", ''], ["ru-ob", ''], ["ru-sr", ''], ["ru-tt", ''], ["ru-to", ''], ["ru-ty", ''], ["ru-ga", ''], ["ru-kk", ''], ["ru-cn", ''], ["ru-kl", ''], ["ru-da", ''], ["ru-ro", ''], ["ru-bl", ''], ["ru-tu", ''], ["ru-ir", ''], ["ru-ct", ''], ["ru-yv", ''], ["ru-am", ''], ["ru-tb", ''], ["ru-tl", ''], ["ru-ng", ''], ["ru-vg", ''], ["ru-kv", ''], ["ru-me", ''], ["ru-ke", ''], ["ru-as", ''], ["ru-pr", ''], ["ru-mg", ''], ["ru-bu", ''], ["ru-kn", ''], ["ru-kd", ''], ["ru-ku", ''], ["ru-al", ''], ["ru-km", ''], ["ru-pe", ''], ["ru-ad", ''], ['ru-kr', '']];

export const dataUa = [['ua-my',''],
                  ['ua-ks',''],
                  ['ua-kc',''],
                  ['ua-zt',''],
                  ['ua-sm',''],
                  ['ua-dp',''],
                  ['ua-kk',''],
                  ['ua-pl',''],
                  ['ua-zp',''],
                  ['ua-ch',''],
                  ['ua-rv',''],
                  ['ua-cv',''],
                  ['ua-if',''],
                  ['ua-km',''],
                  ['ua-lv',''],
                  ['ua-tp',''],
                  ['ua-zk',''],
                  ['ua-vo',''],
                  ['ua-ck',''],
                  ['ua-kh',''],
                  ['ua-kv',''],
                  ['ua-mk',''],
                  ['ua-vi','']];
  
export const dataCyr = [['sy-di', ''],
                   ['sy-hl', ''],
                   ['sy-hm', ''],
                   ['sy-hi', ''],
                   ['sy-id', ''],
                   ['sy-ha', ''],
                   ['sy-dy', ''],
                   ['sy-su', ''],
                   ['sy-rd', ''],
                   ['sy-qu', ''],
                   ['sy-dr', ''],
                   ['sy-3686', ''],
                   ['sy-la', ''],
                   ['sy-ta', ''],
                   ['sy-ra', '']];
  
export const dataWorld = [
                    ['eu', ''],
                    ['oc', ''],
                    ['af', ''],
                    ['as', ''],
                    ['na', ''],
                    ['sa', '']
  ]

export const mapList = [
    {name: 'Рекомендации', type: 'recommendations'},
    {name: 'Статистика', type: 'statistic'},
    {name: 'План', type: 'plan'},
    {name: 'Организации-участники события', type: 'involved_agencies'},
    {name: 'Хронология', type: 'chronology'},
    {name: 'Метеорологическая обстановка', type: 'meteo'},
]

export const locations = [
    {name: 'Мир'},
    {name: 'Россия'},
    {name: 'Украина'},
    {name: 'Сирия'},
]

export const SOURCES = [
    {name: "РБК", link: "https://rssexport.rbc.ru/rbcnews/news/30/full.rss"},
    {name: "lenta.ru", link: "https://lenta.ru/rss"},
    {name: "gazeta.ru", link: "https://www.gazeta.ru/export/rss/first.xml"},
    {name: "ria.ru", link: "https://ria.ru/export/rss2/archive/index.xml"},
    {name: "Коммерсант", link: "https://www.kommersant.ru/RSS/news.xml"},
    {name: "Ведомости", link: "https://www.vedomosti.ru/rss/news.xml"},
    {name: "АИФ", link: "https://aif.ru/rss/news.php"},
    {name: "BFM", link: "https://www.bfm.ru/news.rss?container_breaking=8"},
    {name: "CNN", link: "https://rss.cnn.com/rss/edition_world.rss"},
    {name: "BBC", link: "https://feeds.bbci.co.uk/russian/rss.xml"},
    {name: "Reuters", link: "https://www.reutersagency.com/feed/?taxonomy=best-regions&post_type=best"},
    {name: "BBC-EN", link: "https://feeds.bbci.co.uk/news/world/rss.xml"},
    {name: "ROSENERGOATOM", link: "https://www.rosenergoatom.ru/zhurnalistam/rss/"}
]

export const ROLES = [
    'Администратор',
    'Руководитель',
    'Оператор',
    'Мониторинг'
]

export const levels = [
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
]