import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_OPOS_LIST, SET_OPOS_LOADING } from "./types";


export const getOpoList = () => async (dispatch, getState) => {
  dispatch({ type: SET_OPOS_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/opos`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { opo } = await response.json();
      dispatch({ type: SET_OPOS_LIST, payload: opo});
    }
  }
  dispatch({ type: SET_OPOS_LOADING, payload: false });
};

export const createOpo = (data) => async (dispatch, getState) => {
  dispatch({ type: SET_OPOS_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/opo`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getOpoList());
    }
  }
  dispatch({ type: SET_OPOS_LOADING, payload: false });
};

export const updateOpo = (id, data) => async (dispatch, getState) => {
  dispatch({ type: SET_OPOS_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/opo/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getOpoList());
    }
  }
  dispatch({ type: SET_OPOS_LOADING, payload: false });
};

export const deleteOpo = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_OPOS_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/opo/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getOpoList());
    }
  }
  dispatch({ type: SET_OPOS_LOADING, payload: false });
};