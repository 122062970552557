import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getOpoList, createOpo, updateOpo, deleteOpo } from '../../actions/opos'
import { useEffect } from 'react'
import Li from '../../components/Li'
import Loader from '../../components/Loader/Loader'
import Modal from 'react-responsive-modal'
import { useState } from 'react'
import { useFormik } from 'formik'
import Button from '../../components/Button'
import { Input, Select, Textarea } from '../../components/ModalInputs'
import { IoMdClose } from "react-icons/io";
import * as Yup from 'yup'
import boss from '../../assets/img/photo_boss.jpg'
import { FaUser } from "react-icons/fa";

const validationSchema = Yup.object({
  // name: Yup.string().required('обязательное поле'),
  // code: Yup.string().required('обязательное поле'),
})


const initialFormValues = {
  address: null,
  contacts: [],
  email: null,
  fax: null,
  hazard_category: null,
  locality: null,
  opo_name: null,
  opo_organization: null,
  orgn: null,
  telephone: null,
  tin: null,
  coordinates: null,
  square: null,
  activity: null,
  storageСhar: null,
  storageMethod: null,
  consumption: null,
  protection: null,
  staff: null,
  population: null,
  rescuers: null,
  safety: null,
  boss: null,
  boss_pos: null,
  boss_tel: null,
}

const HAZARD_CATEGORY_LIST = [
  { value: 1, name: 'I класс опасности'},
  { value: 2, name: 'II класс опасности'},
  { value: 3, name: 'III класс опасности'},
  { value: 4, name: 'IV класс опасности'}
]

export default function Opos () {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.opos)
  
  const [selectedOpo, setSelectedOpo] = useState(null)
  
  useEffect(() => {
    dispatch(getOpoList())
  }, [])
  
  const renderLi = (opo) => {
    const { id, opo_name } = opo
    return (
      <Li key={id} onClick={() => setSelectedOpo(opo)}>
        <div className={styles.firstLiBlock}>
          <div>{opo_name}</div>
        </div>
        <IoMdClose color='red' size={24} onClick={deleteOpoHandler(id)}/>
      </Li>
    )
  }

  const deleteOpoHandler = (id) => (e) => {
    e.stopPropagation()
    dispatch(deleteOpo(id))
  }

  const closeModal = () => {
    setSelectedOpo(null)
  }
  
  return (
    <div className={styles.wrapper}>
      <Button onClick={() => setSelectedOpo({})}>Добавить объект</Button>
      <h2>Объекты ОПО</h2>
      {loading
        ? <Loader top='30%' left='46.5%'/>
        : !!list.length && <ul>{list.map(renderLi)}</ul>
      }
      <OpoModal
        open={!!selectedOpo}
        onClose={closeModal}
        data={selectedOpo}
        addMode={!selectedOpo?.id}
      />
    </div>
  )
}


const OpoModal = ({ open, onClose, data, addMode }) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    open && setValues(addMode ? initialFormValues : data)
  }, [open])
  
  const submitForm = ({id, ...values}) => {
    id ? dispatch(updateOpo(id, values)) : dispatch(createOpo(values))
    onClose()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    handleBlur,
    setValues
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm
  })
  
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={open}
      classNames={{ modal: styles.modal }}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >

      <h2>{addMode ? 'Добавление объекта' : 'Редактирование объекта'}</h2>

      <div className={styles.wrapperInput}>

      <div className={styles.wrapperRow}>
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.address}
        onChange={handleChange}
        onBlur={handleBlur}
        name='address'
        label='Адрес'
        error={error('address')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        name='email'
        label='E-mail'
        error={error('email')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.fax}
        onChange={handleChange}
        onBlur={handleBlur}
        name='fax'
        label='Факс'
        error={error('fax')}
      />
      </div>

      <div className={styles.wrapperRow}>
      <Select
        style={{width: '100%', maxWidth: '100%'}}
        value={values.hazard_category}
        onChange={handleChange}
        onBlur={handleBlur}
        name='hazard_category'
        label='Категория риска'
        error={error('hazard_category')}
      >
        <option value=''>Выбрать категорию</option>
        {HAZARD_CATEGORY_LIST.map(({ value, name }) => (
          <option key={value} value={value}>{name}</option>
        ))}
      </Select>

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.locality}
        onChange={handleChange}
        onBlur={handleBlur}
        name='locality'
        label='Населенный пункт'
        error={error('locality')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.opo_name}
        onChange={handleChange}
        onBlur={handleBlur}
        name='opo_name'
        label='Название объекта'
        error={error('opo_name')}
      />
      </div>

      <div className={styles.wrapperRow}>
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.opo_organization}
        onChange={handleChange}
        onBlur={handleBlur}
        name='opo_organization'
        label='Организация'
        error={error('opo_organization')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.orgn}
        onChange={handleChange}
        onBlur={handleBlur}
        name='orgn'
        label='ОГРН'
        error={error('orgn')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.telephone}
        onChange={handleChange}
        onBlur={handleBlur}
        name='telephone'
        label='Телефон'
        error={error('telephone')}
      />
      </div>


      <div className={styles.wrapperRow}>
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.tin}
        onChange={handleChange}
        onBlur={handleBlur}
        name='tin'
        label='ИНН'
        error={error('tin')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.coordinates || (addMode ? '' : '56°04′38″ с. ш. 47°30′35″ в. д.')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='coordinates'
        label='Координаты'
        error={error('coordinates')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.square || (addMode ? '' : '4500 кв. м.')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='square'
        label='Площадь'
        error={error('square')}
      />
      </div>

      <div className={styles.wrapperRow}>
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.storageMethod || (addMode ? '' : 'Изотермический')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='storageMethod'
        label='Способ хранения'
        error={error('storageMethod')}
      />
      
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.consumption || (addMode ? '' : '0,5 т/сутки')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='consumption'
        label='Потребление АХОВ'
        error={error('consumption')}
      />

      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.staff || (addMode ? '' : '280 чел.')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='staff'
        label='Персонал'
        error={error('staff')}
      />
      
      </div>
      
      <div className={styles.wrapperRow}>
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.population || (addMode ? '' : '980 чел.')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='population'
        label='Население, проживающее на СЗЗ'
        error={error('population')}
      />
      
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.safety || (addMode ? '' : 'BSL - 3 (4 ед.), BSL - 4 (1 ед.)')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='safety'
        label='Уровень безопасности'
        error={error('safety')}
      />
      </div>
      
      <div className={styles.wrapperRow}>
      <Textarea
        style={{width: '100%', maxWidth: '100%'}}
        value={values.activity || (addMode ? '' : 'Производство азотных удобрений - 11000 т/год')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='activity'
        label='Основная деятельность'
        error={error('activity')}
      />
      
      <Textarea
        style={{width: '100%', maxWidth: '100%'}}
        value={values.storageСhar || (addMode ? '' : 'Вертикальные резервуары емкостью 10т. - 4шм. Магистрали по перекачке - 110 м. (объем 9куб. м.)')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='storageСhar'
        label='Характеристики хранилища'
        error={error('storageСhar')}
      />
      </div>
      
      <div className={styles.wrapperRow}>
      
      </div>

      <div className={styles.wrapperRow}>
      <Textarea
        style={{width: '100%', maxWidth: '100%'}}
        value={values.rescuers || (addMode ? '' : '30 чел., техника - 2 ед. (от предприятия), 20 чел., техника - 5 ед. (от ГУ МЧС России)')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='rescuers'
        label='Аварийно-спасательный отряд'
        error={error('rescuers')}
      />

      <Textarea
        style={{width: '100%', maxWidth: '100%'}}
        value={values.protection || (addMode ? '' : 'Обваловка резервуаров - высота 1,5 м. Пожарная сигнализация, датчики учетки аммиака.')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='protection'
        label='Защита и безопасность'
        error={error('protection')}
      />
      </div>

      <div className={styles.wrapperRow}>
      <div style={{width: '100%', maxWidth: '100%'}}>
      <Input
        style={{width: '100%', maxWidth: '100%', marginBottom: '20px'}}
        value={values.boss || (addMode ? '' : 'Увакин Александр Владимирович')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='boss'
        label='Руководитель'
        error={error('boss')}
      />
      <Input
        style={{width: '100%', maxWidth: '100%', marginBottom: '20px'}}
        value={values.boss_pos || (addMode ? '' : 'Заместитель ген. директора - директор филиала АО "Концерн Росэнергоатом"')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='boss_pos'
        label='Должность руководителя'
        error={error('boss_pos')}
      />
      <Input
        style={{width: '100%', maxWidth: '100%'}}
        value={values.boss_tel || (addMode ? '' : '8(910)278-23-24')}
        onChange={handleChange}
        onBlur={handleBlur}
        name='boss_tel'
        label='Телефон руководителя'
        error={error('boss_tel')}
      />
      </div>

      <div className={styles.photoBlock}>
        <span className={styles.photoTitle}>Фото руководителя</span>
        {addMode ? <div className={styles.noPhoto}><FaUser/></div> : <img className={styles.photo} src={boss} alt="Фото руководителя"/>}
      </div>

      </div>
      
      </div>
      <div className={styles.buttonsBlock}>
        <Button onClick={handleSubmit}>Сохранить</Button>
        <Button onClick={onClose}>Закрыть</Button>
      </div>

    </Modal>
  )
}