import React from 'react'


export default function BiologicalSituation () {
  

  return (
  <div style={{display:"flex", flexDirection:"column"}}>
  <img src='https://phb.nintegra.ru/analize/%D0%91%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-23-07.png'/>
  <img src='https://phb.nintegra.ru/analize/БОО/Снимок%20экрана%20от%202023-11-23%2017-23-38.png'/>
  <img src='https://phb.nintegra.ru/analize/%D0%91%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-23-56.png'/>
  <img src='https://phb.nintegra.ru/analize/%D0%91%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-24-33.png'/>
  </div>
  )
}