import React, { useEffect, useState } from 'react'
import styles from './DM.module.css'
import Title from '../../components/Title/Title'
import { useDispatch, useSelector } from 'react-redux';
import { deleteDM, getDM } from '../../actions/DM';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import DMPopup from './DMPopup/DMPopup'
import { MdDelete } from 'react-icons/md';
import Confirmation from '../../components/Confirmation/Confirmation';
const DM = () => {

  const dispatch = useDispatch();
  const { list = [], count, loading } = useSelector(state => state.dm);
  const [search, setSearch] = useState('');
  const [isPopup, setIsPopup] = useState(false);
  const [confirmation, setConfirmation] = useState({ name: null, id: null });

  useEffect(() => {
    dispatch(getDM());
  }, [])

  const acceptConfirmation = (id) => {
    dispatch(deleteDM(id));
    setConfirmation({ name: null, id: null });
  };

    return (
      <>
      {isPopup ? 
      <DMPopup
      item={{}}
      type='ADD'
      setIsPopup={setIsPopup}/> : <></>}
      <div className={styles.wrapper}>
      {
            confirmation.id ?           //Подтверждение Удаление события
            <Confirmation
            top="30px"
            left="40px"
            title="Вы точно хотите удалить вещество?"
            confirmation={confirmation.name}
            acceptConfirmation={() => acceptConfirmation(confirmation.id)}
            rejectConfirmation={() => setConfirmation({ name: "", id: "" })}
          />
          :
          <></>
        }
          <Title title='Список опасных веществ'/>
          <div className={styles.search}>
          <div className={styles.button_add} onClick={() => setIsPopup(true)}>Добавить</div>
            <input 
            className={styles.searchInput}
            type="text" 
            value={search} 
            onChange={e => setSearch(e.target.value)} 
            placeholder='Поиск' />
          </div>

          <div className={styles.listWrapper}>
          {loading && <div className={styles.wrapperLoader}><Loader color="003f70"/></div>}
          
          {!loading &&
            list.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(item => (
                <div className={styles.itemRow}>
                <Link 
                key={item.id}
                to={`/dm/${item.id}`}
                className={styles.listItem}>{item.name}</Link>
                <i className={styles.delete} title='Удалить' onClick={() => setConfirmation({name: item.name, id: item.id})}><MdDelete/></i>
                </div>
            ))
          }
          </div>

      </div>
    </>
  )
}

export default DM