import React from 'react'
import styles from './styles.module.css'
import { IoSettingsSharp } from 'react-icons/io5'



// const validationSchema = Yup.object({
//   name: Yup.string().required('обязательное поле'),
//   code: Yup.string().required('обязательное поле'),
// })


// const initialFormValues = {
//   name: '',
//   code: ''
// }

// const genSetList = [
//   "Наименование ИС",
//   "Должность руководителя",
//   "Звание руководителя",
//   "ФИО руководителя",
  
// ] 
// const secrurity =[ 

//   "Длина пароля",
//   "Срок смены пароля",
//   "Запрет на использование ранее введенного пароля",
//   "Контроль наличия заглавных букв и спец. символов"
// ]

export default function GeneralSettings () {
   
  //     const renderLi = (item) => <Li>
  //   {item}
   
  // </Li>
  
  return (
    <div className={styles.wrapper}>
      <h2>Общие настройки</h2>
      <div style={{display: "flex", justifyContent:"center"}}>
        <div>
          <ul>
          <li className={styles.li}>Наименование ИС</li>
          <li className={styles.li}>Должность руководителя</li>
          <li className={styles.li}>Звание руководителя</li>
          <li className={styles.li}>ФИО руководителя</li>
         
          </ul>
        </div>
        <div>
          <ul>
          <li className={styles.li}>Второй отдел Шестого управления</li>
          <li className={styles.li}>Начальник отдела</li>
          <li className={styles.li}>Полковник</li>
          <li className={styles.li}>Петров Анатолий Иванович</li>
          </ul>
        </div>
      </div>
      <h2>Безопасность</h2>
      <div style={{display: "flex", justifyContent:"center"}}>
        <div>
          <ul>
          <li className={styles.li}>Длина пароля</li>
          <li className={styles.li}>Срок смены пароля</li>
          <li className={styles.li}>Запрет на использование ранее введенного пароля</li>
          <li className={styles.li}>Контроль наличия заглавных букв и спец. символов</li>
          </ul>
        </div>
        <div>
          <ul>
          <li className={styles.li}>Более 8 символов</li>
          <li className={styles.li}>раз в два месяца</li>
         <input  className={styles.inputGenSet} type="checkbox" />
         <input  className={styles.inputGenSet} type="checkbox" />

          </ul>
        </div>
        </div>


    </div>
  )

  }
