import React, { useEffect, useState } from 'react'
import styles from './Gis.module.css'
import proj4 from 'proj4';
import Yamap from '../../components/Map/Yamap'
import { editNewEvent, getEvents } from '../../actions/events';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import HighMaps from '../../components/HighMaps/HighMaps';

if (typeof window !== 'undefined') {
    window.proj4 = window.proj4 || proj4;
  }
const Gis = () => {
    const dispatch = useDispatch();
    const events = useSelector(state => state.events.data.events);

    const [mapType, setMapType] = useState(0);
    
    useEffect(() => {
        dispatch(getEvents({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')}));
    }, [])

    useEffect(() => {
        events && events.map(event => {
            if (event?.geo_text && event.geo_position == null) {
                geo_text(event)        
            }
        })
    }, [events])

    const geo_text = (event) => {
        fetch(`https://geocode-maps.yandex.ru/1.x?apikey=9e115931-69dc-4f36-b58c-ce53f85a6f89&geocode=${event.geo_text}&format=json`)
        .then(res => res.json()).then(data => {
            const coord = data && data?.response?.GeoObjectCollection.featureMember?.[0]?.GeoObject?.Point?.pos.split(' ');
            (coord && coord[1] && coord[0]) && dispatch(editNewEvent(event.id, {...event, geo_position: [Number(coord[1]), Number(coord[0])] }));
        }).finally(() => {
            dispatch(getEvents({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')}));
        })
    }

    return (
    <div className={styles.wrapper}>
        <select onChange={e => setMapType(e.target.value)} className={styles.select}>
            <option value={0}>Яндекс карты</option>
            <option value={1}>OpenStreetMap</option>
            <option value={1}>ГИС Базис</option>
            <option value={1}>ГИС Оператор</option>
            <option value={1}>ГИС Zulu</option>
        </select>
        <div className={styles.map}>         
            
            { events && mapType == 0 ? 
                    <Yamap 
                        center={[39.8, 10]}
                        width={'100%'} heigth="100%" 
                        zoom={2.3}
                        controls={['TypeSelector']}
                        name="place"
                        events={events}
                        isGis={true}
                        />
                        : <></>}
            { events && mapType == 1 ?
                <HighMaps 
                    geo={[39.8, 10]} 
                    zoom={2.3} 
                    width={'100%'} 
                    height='65vh'
                    events={events} />
                    :<></>}
        </div>
    </div>
  )
}

export default Gis