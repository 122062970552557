import React, { useState } from 'react'
import styles from './DMPopup.module.css'
import { createNotification } from '../../../components/Notifications/Notifications';
import { setNewDM, editDM } from '../../../actions/DM';
import { useDispatch } from 'react-redux';
import { IoCloseCircleOutline } from 'react-icons/io5';

const DMPopup = ({type, item, setIsPopup}) => {

    const dispatch = useDispatch();
    const [item_, setItem_] = useState(item || {});
    const handleClose = () => {
        setIsPopup(false);
        setItem_({});
    }

    const handleSend = () => {
        if (item_.name) {
            if (type == 'ADD') {
                dispatch(setNewDM(item_));
            } else {
                dispatch(editDM(item_));
            }
            handleClose();
        } else {
            createNotification('error', 'Поле "Название вещества" обязательно для заполнения');
        }
    }

    const handleChange = (value, name) => {
        setItem_((prev) => ({...prev, [name]: value}));
    }

    return (
    <div className={styles.bg}>
        <div className={styles.wrapper}>
            <div className={styles.close} onClick={handleClose}><IoCloseCircleOutline fontSize={35}/></div>
            <div className={styles.title}>{type == 'ADD' ? "Добавление вещества" : "Редактирование вещества"}</div>
            
            <div className={styles.imgAndInput}>
            <div className={styles.inputBlock}>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Название вещества:</span>
              <input onChange={e => handleChange(e.target.value, e.target.name)} name='name' className={styles.inputItemInput} type="text" value={item_.name}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Синонимы:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='synonyms' className={styles.inputItemInput} type="text" value={item_.synonyms}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Торговые названия:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='trade_name' className={styles.inputItemInput} type="text" value={item_.trade_name}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Структурная формула:</span>
              <input onChange={e => handleChange(e.target.value, e.target.name)} name='formula' className={styles.inputItemInput} type="text" value={item_.formula}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер РПОХВ:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='reg_rp' className={styles.inputItemInput} type="text" value={item_.reg_rp}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер CAS:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='reg_cas' className={styles.inputItemInput} type="text" value={item_.reg_cas}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер RTECS:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='reg_rtecs' className={styles.inputItemInput} type="text" value={item_.reg_rtecs}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер EC:</span>
                <input onChange={e => handleChange(e.target.value, e.target.name)} name='reg_ec' className={styles.inputItemInput} type="text" value={item_.reg_ec}/>
            </div>
            </div>

            <div className={styles.inputImg}>
                {/* {item?.files?.map(file => (
                    <img key={file.id} src={file.url} title={file.comment} className={styles.img}/>
                ))} */}
                {item?.formulaUrl ? <img src={item?.formulaUrl} title={'Формула'} className={styles.img}/> : ''}
            </div>
          </div>

        <div className={styles.wrapperButton}>
            <div className={styles.button} onClick={handleClose}>Отменить</div>
            <div className={styles.button} onClick={handleSend}>{type == 'ADD' ? "Добавить" : "Сохранить"}</div>
        </div>
        </div>
    </div>
  )
}

export default DMPopup