import React, { useState } from 'react'
import styles from './Files.module.css'
import { IoMdClose } from "react-icons/io";
import { IoCloseCircleOutline } from 'react-icons/io5';
import Button from '../../../components/Button';
import { deleteFile, uploadFile } from '../../../actions/events';
import { useDispatch } from 'react-redux'
import { FaFileLines } from "react-icons/fa6";


const Files = ({id, setPopupByType, files}) => {

    const [deleteFiles, setDeleteFiles] = useState([]);
    const dispatch = useDispatch();

    const handleClose = () => {
        setPopupByType('');
    }

    const handleAddFile = () => {
        if (deleteFiles.length > 0) {
            deleteFiles.forEach((file_id) => {
                dispatch(deleteFile(id, file_id));
            })
        }
        setDeleteFiles([]);
        setPopupByType('');
    }

    const handleUploadFile = (e) => {
        if (e.target.files) {
            const files = Object.values(e.target.files);
            files.forEach((file) => {
            dispatch(uploadFile(id, file));
            })
        }
      };

    return (
    
    <div className={styles.wrapper_bg}>
        <div className={styles.wrapper}>
        <div className={styles.close} onClick={handleClose}><i><IoCloseCircleOutline/></i></div> 
        <div className={styles.title}>Файлы</div>
        <div className={styles.listWrapper}>
            {[...files].filter(el => !deleteFiles.includes(el.id)).map(el =>
                <div className={styles.file} key={el.id}>
                    <i className={styles.close_icon} onClick={() => setDeleteFiles(prev => [...prev, el.id])}><IoMdClose/></i>
                    <i className={styles.icon}><FaFileLines/></i>
                    <a href={el.url} title={el.filename} download target="_blank" className={styles.name}>{el.filename}</a>
                </div>
            )}
        </div>

        <input
            className={styles.inputFile} 
            type="file"
            multiple
            title="Выберите файл"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
            onChange={(e) => handleUploadFile(e)}
        />

        <Button onClick={handleAddFile}>Сохранить</Button>
        </div>
    </div>
  )
}

export default Files