import React from 'react'
import {AiOutlineEye, AiOutlineUser, AiOutlineReddit} from "react-icons/ai";
import {BiLockAlt} from "react-icons/bi";
import styles from './styles.module.css'
import cn from 'classnames'

export default function Input({
    ...props
}) {

    const passwordMode = props.type === 'password'

    const toggleShowPassword = () => {
        const passInput = document.getElementsByName('password')[0]
        const showIcon = document.getElementsByClassName(styles.showIcon)[0]
        const type = passInput.getAttribute("type") === "password"
            ? "text"
            : "password";
        passInput.setAttribute("type", type);
        showIcon
            .classList
            .toggle(styles.active)
    }

    return (
        <div className={styles.wrapper}>
            {passwordMode
                ? <BiLockAlt className={styles.icon}/>
                : <AiOutlineUser className={styles.icon}/>}
            <input {...props}/> {passwordMode && <AiOutlineEye
                className={cn([styles.icon, styles.showIcon])}
                onClick={toggleShowPassword}/>}
        </div>
    )
}
