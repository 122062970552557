import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_MESSAGE_LOADING, SET_MESSAGE_LIST } from "./types";


export const getMessageList = () => async (dispatch, getState) => {
  dispatch({ type: SET_MESSAGE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/messages`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { messages } = await response.json();
      dispatch({ type: SET_MESSAGE_LIST, payload: messages});
    }
  }
  dispatch({ type: SET_MESSAGE_LOADING, payload: false });
};

export const createMessage = (data) => async (dispatch, getState) => {
  dispatch({ type: SET_MESSAGE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/message`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getMessageList());
    }
  }
  dispatch({ type: SET_MESSAGE_LOADING, payload: false });
};

export const updateMessage = (id, data) => async (dispatch, getState) => {
  dispatch({ type: SET_MESSAGE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/message/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getMessageList());
    }
  }
  dispatch({ type: SET_MESSAGE_LOADING, payload: false });
};

export const deleteMessage = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_MESSAGE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/message/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getMessageList());
    }
  }
  dispatch({ type: SET_MESSAGE_LOADING, payload: false });
};