import React from 'react'
import styles from './styles.module.css'
import Table from '../../components/Table'
import { SOURCES } from '../../assets/const'

export default function Sources () {
  
  const renderTr = ({name, link}) => <tr>
    <td>{name}</td>
    <td>
      <a
        href={link}
        target='_blank'
        rel='noreferrer noreferrer'
      >
        {link}
      </a>
    </td>
  </tr>
  
  return (
    <div className={styles.wrapper}>
      <h2>Источники информации</h2>
      <Table>
        <tbody>
          {SOURCES.map(renderTr)}
        </tbody>
      </Table>
    </div>
  )
}
