import React from "react";
import {BrowserRouter, Navigate, Route, Routes as Switch} from "react-router-dom";

import {PrivateRoute} from "../components/Routing";

import Layout from "../components/Layout";
import Auth from '../pages/Auth'
import Main from "../pages/Main/index";
import NotFound from "../pages/NotFound/NotFound";
import News from '../pages/News/News'
import Events from '../pages/Events/Events'
import Gis from '../pages/Gis/Gis'
import DM from '../pages/DM/DM'
import SingleEvents from '../pages/SingleEvents/SingleEvents'
import Disease from "../pages/Disease";
import Sources from "../pages/Sources";
import Roles from "../pages/Roles";
import Messages from "../pages/Messages";
import Opos from "../pages/Opos";
import SingleDM from '../pages/SingleDM/SingleDM'

import ChemicalSituation from "../pages/ChemicalSituation";
import RadiationSituation from "../pages/RadiationSituation";
import BiologicalSituation from "../pages/BiologicalSituation";

import GeneralSettings from "../pages/GeneralSettings";
import Backup from "../pages/Backup";
import Meteo from "../pages/Meteo/Meteo";
import Users from "../pages/Users";
import Access from "../pages/Access";
import SecurityLog from "../pages/SecurityLog";


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/auth' element={< Auth />}/>
                <Route element={< PrivateRoute />}>
                    <Route path="/" element={< Layout />}>
                        <Route index element={< Main />}/>
                        <Route path="/news" element={< News />}/>
                        <Route path="/events" element={< Events />}/>
                        <Route path="/roles" element={< Roles />}/>
                        <Route path="/diseases" element={< Disease />}/>
                        <Route path="/messages" element={<Messages />}/>
                        <Route path="/opos" element={<Opos />}/>
                        <Route path="/sources" element={< Sources />}/>
                        <Route path="/gis" element={< Gis />}/>
                        <Route path="/meteo" element={< Meteo />}/>
                        <Route path="/dm" element={< DM />}/>
                        <Route path="/dm/:id" element={< SingleDM />}/>
                        <Route path="/events/:id" element={< SingleEvents />}/>
                        
                        <Route path="/generalSettings" element={< GeneralSettings />}/>
                        <Route path="/backup" element={< Backup />}/>
                        <Route path="/users" element={< Users />}/>
                        <Route path="/access" element={< Access />}/>
                        <Route path="/securityLog" element={< SecurityLog />}/>

                        <Route path="/chemicalSituation" element={< ChemicalSituation />}/>
                        <Route path="/radiationSituation" element={< RadiationSituation />}/>
                        <Route path="/biologicalSituation" element={< BiologicalSituation />}/>

                        <Route path="/notFound" element={< NotFound />}/>
                    </Route>
                </Route>
                <Route path="*" element={< Navigate to = '/notFound' replace />}/>
            </Switch>
        </BrowserRouter>
    );
}