import React from 'react'
import styles from './styles.module.css'
import { ROLES } from '../../assets/const'
import Li from '../../components/Li'
import { IoSettingsSharp } from "react-icons/io5";



export default function Roles () {
  
  const renderLi = (role) => <Li>
    {role}
    <IoSettingsSharp size={20} color='#003f70'/>
  </Li>
  
  return (
    <div className={styles.wrapper}>
      <h2>Список ролей</h2>
      <ul>{ROLES.map(renderLi)}</ul>
    </div>
  )
}
