import React, { useState, useEffect } from 'react'
import styles from './Events.module.css'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete, MdEdit } from "react-icons/md";
import Title from '../../components/Title/Title';
import { Link } from 'react-router-dom';
import { getEvents, newEvent, deleteEvent, editNewEvent } from '../../actions/events';
import Loader from '../../components/Loader/Loader'
import { createNotification } from '../../components/Notifications/Notifications';
import Confirmation from '../../components/Confirmation/Confirmation';
import Edit from './Popups/Edit/Edit';
import Add from './Popups/Add/Add';
import { Pagination } from '../../components/Pagination/Pagination';

const News = ({}) => {
  const [itemList, setItemList] = useState([]);
  const [date, setDate] = useState({from: moment().format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD')});
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState('');
  const [eventL, setEventL] = useState({});


  const [newItem, setNewItem] = useState({
    incident_type:'', 
    meteo: {},  
    last_detected: moment().format('YYYY-MM-DD'), 
    geo_position: '',
    geo_text: '',
    recommendations: [],
    chronology: [],
    statistic: [],
    plan: [],
    involved_agencies: [],
    meteo: [],
    level: '1',
});
  const [minHour, setMinHour] = useState({min: moment().minutes(), hour: moment().hour()});
  const [confirmation, setConfirmation] = useState({ txt: null, id: null });
  const [activePage, setActivePage] = useState(0); // активная страница

    const dispatch = useDispatch();
    const events = useSelector(state => state.events);
    const { loading } = events;

    useEffect(() => {
        setItemList(events.data);
    }, [events])

    useEffect(() => {
        date && dispatch(getEvents(date));
    }, [date])

    const handleAddEvent = () => {
        if (newItem.title) {
            const newItem_ = {
                ...newItem,
                last_detected: `${newItem.last_detected} ${minHour.hour}:${minHour.min}:00.486747`,
                geo_position: newItem?.geo_position ? [Number(newItem.geo_position?.split(',')[0]), Number(newItem.geo_position?.split(',')[1])] : null
            }
            dispatch(newEvent(newItem_, date));
            setIsPopup(false);
            setNewItem({});
        } else {
            createNotification("error", "Заполните обязательные поля");
        }
    }

    const handleSaveEditEvent = () => {
        const obj = {
            ...eventL
        }
        if (obj.title) {
            const eventL_ = {
                ...obj,
                last_detected: `${moment(obj.last_detected).format('YYYY-MM-DD')} ${minHour.hour}:${minHour.min}`,
                geo_position:    obj?.geo_position ? [Number(obj?.geo_position.split(',')[0]), Number(obj?.geo_position.split(',')[1])] : null
            }
            dispatch(editNewEvent(eventL_.id, eventL_, date));
            setIsPopup(false);
        } else {
            createNotification("error", "Заполните обязательные поля");
        }
    }

    const handleCloseEvent = () => {
        setIsPopup(false);
        setNewItem({});
    }

    const deleteItem = (id) => {
        dispatch(deleteEvent(id,  date));
    }

    const acceptConfirmation = (id) => {
        deleteItem(id)
        setConfirmation({ txt: null, id: null });
      };

      const handleEditEvent = (type, event) => {
          if (type == 'edit') {
              getById(event);
            } 
        setType(type);
        setIsPopup(true);
      } 

      const getById = (event) => {
        if (event && event.id) {
            setEventL(({...event, 
            geo_position: 
            event?.geo_position ?
            `${
                    event?.geo_position?.split(',')?.[0]?.replace('{', '') + ',' + 
                    event?.geo_position?.split(',')?.[1]?.replace('}', '')
                
            }`: ''}
            ))
        }
    }

    const handleSetActivePage = (number) => {
        setActivePage(number);
        dispatch(getEvents(date, number));
    }

    return (
    <div className={styles.wrapper}>

        {isPopup && type == 'edit' && newItem ?   //Редактирование содбытия
            <Edit 
                eventL={eventL} 
                setEventL={setEventL} 
                handleCloseEvent={handleCloseEvent} 
                minHour={minHour} 
                setMinHour={setMinHour}
                handleSaveEditEvent={handleSaveEditEvent}
                />
            : null
        }
        
        {
            isPopup && type == 'add' && newItem ?  //Добавление события
            <Add
            handleAddEvent={handleAddEvent}
            newItem={newItem}
            setNewItem={setNewItem}
            handleCloseEvent={handleCloseEvent}
            setMinHour={setMinHour}
            minHour={minHour}
            />
            : null
        }

        {
            confirmation.id ?           //Подтверждение Удаление события
            <Confirmation
            top="30px"
            left="40px"
            title="Вы точно хотите удалить событие?"
            confirmation={confirmation.text}
            acceptConfirmation={() => acceptConfirmation(confirmation.id)}
            rejectConfirmation={() => setConfirmation({ text: "", id: "" })}
          />
          :
          <></>
        }

        <Title title='События'/>
    
        <div className={styles.dateWrapper}>
            <div className={styles.inputBlock}>
                <span>Дата с:</span>
                <input type="date" value={date.from} onChange={e => setDate(prev => ({...prev, from: e.target.value}))}/>
            </div>

            <div className={styles.inputBlock}>
                <span>Дата до:</span>
               <input type="date" value={date.to} onChange={e => setDate(prev => ({...prev, to: e.target.value}))}/>
            </div>
        </div>
    
        <div className={styles.block}>
            <div className={styles.dateCounter}>Всего событий: <span>{itemList?.num_events}</span></div>
            <div className={styles.button} onClick={() => handleEditEvent('add')}>Добавить событие</div>
        </div>

        <div className={styles.newsWrapper}>
            
            {loading ? <Loader color='#003f70'/> : <></>}

            {itemList && itemList?.events?.map(news => (
                <div 
                    className={styles.newsCard}
                    key={news.id}>
                    <MdDelete title='Удалить' className={styles.delete} onClick={() => setConfirmation({text: news.title, id: news.id})}/>
                    <MdEdit title='Редактировать' className={styles.edit} onClick={() => handleEditEvent('edit', news)}/>
                    <Link to={`/events/${news.id}`} className={styles.link}>
                            <div className={styles.wrapperNum}>
                                <span title='Номер' className={styles.number}>№ {news.id}</span>
                                <div>{news?.control ? <div className={styles.control}>На контроле</div> : ''}</div>   
                            </div>
                            <h3>{news.title}</h3>
                            <h4>Адрес - {news.geo_text}</h4>
                            <h5>Тип - {news.incident_type}</h5>
                            <span>{moment(news.last_detected).format('DD.MM.YYYY, H:mm')}</span>
                    </Link>
                    </div>
            ))
        }
        </div>
        <Pagination pageCount={Math.ceil(itemList.num_events / 10)} numberPage={activePage} onPageChange={e => handleSetActivePage(e.selected)}/>
    </div>
  )
}

export default News