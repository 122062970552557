import React from 'react'
import styles from './Switch.module.css'

const Switch = ({isChecked = false, onChange = () => {}, disabled = false, title = ''}) => {
  return (
    <label className={styles.switch} title={title}>
    <input
      type="checkbox"
      checked={isChecked}
      disabled={disabled}
    />
    <span
      className={styles.slider} 
      onClick={e => onChange(e)}
    />
  </label>
  )
}

export default Switch