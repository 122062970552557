import * as types from "../actions/types";

const initialState = {
    loading: false
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.AUTH_COMPLETED:
            return {
                ...state,
                ...action.user
            };
            // case types.SET_USER_PERMISSIONS:   return {     ...state,     permissions:
            // action.payload   };
        case types.SIGNED_OUT:
            return {
                ...initialState
            };
        case types.SET_AUTH_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}