import React, { useEffect, useState } from 'react'
import styles from './SingleDM.module.css'
import Title from '../../components/Title/Title'
import { useDispatch, useSelector } from 'react-redux';
import { getDMById } from '../../actions/DM';
import { Link, useParams } from 'react-router-dom';
import DMPopup from '../DM/DMPopup/DMPopup';
import Loader from '../../components/Loader/Loader';
// import second from './'

const SingleDM = ({}) => {

  const dispatch = useDispatch();
  const { item = {}, loading } = useSelector(state => state.dm);
  const [isPopup, setIsPopup] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDMById(id));
  }, [id])

  return (
    <>
    {isPopup ? 
      <DMPopup
      item={item}
      type='EDIT'
      setIsPopup={setIsPopup}
      /> : <></>}
    
    {loading ? <div className={styles.wrapperLoader}><Loader/></div> : <></>}

    {!loading ? 
    <div className={styles.wrapper}>
        <div className={styles.title}>ОПАСНЫЕ ВЕЩЕСТВА: <span className={styles.name}>{item.name}</span>
        <div className={styles.button_add} onClick={() => setIsPopup(true)}>Редактировать</div>
        </div>
    
        <div className={styles.wrapperInput}>
          <div className={styles.inputItem}>
            <span className={styles.inputItemName}>Синонимы:</span>
            <input className={styles.inputItemInput} type="text" value={item.synonyms}/>
          </div>
          <div className={styles.inputItem}>
            <span className={styles.inputItemName}>Торговые названия:</span>
            <input className={styles.inputItemInput} type="text" value={item.trade_name}/>
          </div>

          <div className={styles.imgAndInput}>
            <div className={styles.inputBlock}>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Структурная формула:</span>
              <input className={styles.inputItemInput} type="text" value={item.formula}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер РПОХВ:</span>
                <input className={styles.inputItemInput} type="text" value={item.reg_rp}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер CAS:</span>
                <input className={styles.inputItemInput} type="text" value={item.reg_cas}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер RTECS:</span>
                <input className={styles.inputItemInput} type="text" value={item.reg_rtecs}/>
            </div>
            <div className={styles.inputItem}>
                <span className={styles.inputItemName}>Регистрационный номер EC:</span>
                <input className={styles.inputItemInput} type="text" value={item.reg_ec}/>
            </div>
            </div>

            <div className={styles.inputImg}>
                {/* {item?.files?.map(file => (
                    <img key={file.id} src={file.url} title={file.comment} className={styles.img}/>
                ))} */}
                {item?.formulaUrl ? <img src={item?.formulaUrl} title={'Формула'} className={styles.img}/> : ''}
            </div>
          </div>
        </div>
    </div>
    
    :<></>}
    </>
  )
}

export default SingleDM