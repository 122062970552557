import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_DISEASE_LIST, SET_DISEASE_LOADING } from "./types";


export const getDiseaseList = () => async (dispatch, getState) => {
  dispatch({ type: SET_DISEASE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/diseases`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_DISEASE_LIST, payload});
    }
  }
  dispatch({ type: SET_DISEASE_LOADING, payload: false });
};

export const createDisease = (data) => async (dispatch, getState) => {
  dispatch({ type: SET_DISEASE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/disease`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getDiseaseList());
    }
  }
  dispatch({ type: SET_DISEASE_LOADING, payload: false });
};

export const updateDisease = (id, data) => async (dispatch, getState) => {
  dispatch({ type: SET_DISEASE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getDiseaseList());
    }
  }
  dispatch({ type: SET_DISEASE_LOADING, payload: false });
};

export const deleteDisease = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_DISEASE_LOADING, payload: true });
  // await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/disease/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      dispatch(getDiseaseList());
    }
  }
  dispatch({ type: SET_DISEASE_LOADING, payload: false });
};