import React from 'react'
import styles from './styles.module.css'

function Backup() {
  return (

    <div className={styles.wrapper}>
        <h2 style={{marginTop:"30px"}}>Резервное копирование</h2>
        <div className={styles.box__items}>
            <div className={styles.box__time}>
                <h4>Дата Время</h4>
                <ul>
                <li className={styles.li}>24.11.2023 00:04:23</li>
                <li className={styles.li}>23.11.2023 00:03:38 </li>
                <li className={styles.li}>22.11.2023 00:05:47 </li>
                <li className={styles.li}>21.11.2023 00:01:43 </li>
                <li className={styles.li}>20.11.2023 00:02:25 </li>
                <li className={styles.li}>19.11.2023 00:03:08 </li>
                <li className={styles.li}>18.11.2023 00:03:51</li>
                </ul>
            </div>
            <div className={styles.box__file}>
                  <h4>Имя файла</h4>
                <ul>
                <li className={styles.li}>auto_backup_24_11_2023.zip</li>
                <li className={styles.li}>auto_backup_23_11_2023.zip</li>
                <li className={styles.li}>auto_backup_22_11_2023.zip</li>
                <li className={styles.li}> auto_backup_21_11_2023.zip</li>
                <li className={styles.li}> auto_backup_20_11_2023.zip</li>
                <li className={styles.li}>auto_backup_19_11_2023.zip</li>
                <li className={styles.li}>auto_backup_18_11_2023.zip</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Backup
