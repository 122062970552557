import * as types from "../actions/types";

const initialState = {
    data: [],
    loading: false,
    mass_media: []
};

export default function news(state = initialState, action) {
    switch (action.type) {
        case types.SET_NEWS:
            return {
                ...state,
                data: action.payload
            };
        case types.SET_NEWS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case types.SET_MASS_MEDIA:
            return {
                ...state,
                mass_media: action.payload
            };
        default:
            return state;
    }
}