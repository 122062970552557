import React, { useEffect, useState } from 'react'
import styles from './Edit.module.css'
import moment from 'moment'
import { IoAdd, IoCloseCircleOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { levels, locations, mapList, risk } from '../../../../assets/const';
import Yamap from '../../../../components/Map/Yamap';
import { BsGeoFill } from "react-icons/bs";
import Switch from '../../../../components/Switch/Switch';

const Edit = ({eventL, setEventL, handleCloseEvent, setMinHour, minHour, handleSaveEditEvent}) => {
    
    const [isShowMap, setIsShowMap] = useState(false);
    const [item, setItem] = useState('');

    useEffect(() => {
        if (item) {
            setEventL(({...eventL, geo_position: item.join(',')}))
            setIsShowMap(false);   
        }
    }, [item])

    return (
    <div className={styles.popup}>
                <div className={styles.popupContent}>
                    <div className={styles.popupContentInner}>
                    <div className={styles.close} onClick={handleCloseEvent}><IoCloseCircleOutline fontSize={35}/></div>
                    <div className={styles.title}>
                    Редактирование события                    
                    <div className={styles.switchBlock}>
                    <span className={styles.switchTitle}>Взять на контроль</span>
                    <Switch 
                        isChecked={eventL.control} 
                        onChange={e => setEventL(prev => ({...prev, control: !prev.control}))}
                        title='Взять под контроль'
                    />
                    </div>
                    </div>
                    <div className={styles.inputBlock}>
                        <span>Заголовок*:</span>
                        <input type="text" value={eventL.title || ''} onChange={e => setEventL(prev => ({...prev, title: e.target.value}))}/>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Тип:</span>
                        <select onChange={e => setEventL(prev => ({...prev, incident_type: e.target.value}))} className={styles.selectType}>
                            {risk.map(el => <option key={el.title} selected={el.title == eventL.incident_type}>{el.title}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Уровень:</span>
                        <select onChange={e => setEventL(prev => ({...prev, level: e.target.value}))} className={styles.selectType}>
                            {levels.map(el => <option key={el.label} selected={el.value == eventL.level}>{el.label}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Адрес:</span>
                            <input type="text" value={eventL.geo_text || ''} onChange={e => setEventL(prev => ({...prev, geo_text: e.target.value}))}/>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Локация:</span>
                        <select onChange={e => setEventL(prev => ({...prev, location: e.target.value}))} className={styles.select_loc}>
                            {locations.map(el => <option key={el.name} selected={el.name == eventL.location}>{el.name}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span title='[Широта, Долгота]'>Координаты:</span>
                            <input style={{width: `calc(100% - 60px)`}} type="text" value={eventL.geo_position || ''} onChange={e => setEventL(prev => ({...prev, geo_position: e.target.value}))}/>
                            <i 
                                className={styles.mapIcon} 
                                onClick={() => setIsShowMap(true)}
                                title='Открыть карту'
                                ><BsGeoFill/></i>
                    
                            {
                                isShowMap ?
                                <div className={styles.map}>
                                    <Yamap 
                                    coordinate={item} 
                                    width={'100%'} heigth="100%" 
                                    setItem={setItem} 
                                    zoom={1}
                                    center={item || [55.73, 37.9]}
                                    isShowMap={isShowMap}
                                    setIsShowMap={setIsShowMap}
                                    name="byName"/>
                                </div>
                                : <></>
                            }
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Дата события:</span>
                        <div className={styles.inputBlockInner} style={{width: '100%'}}>
                            <input 
                                type="date" 
                                value={moment(eventL.last_detected).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')} 
                                onChange={e => setEventL(prev => ({...prev, last_detected: e.target.value}))}/>
                            <select className={styles.select} onChange={e => setMinHour(prev => ({...prev, hour: e.target.value}))}>{
                            [ ...Array(24).keys()]
                            .map( i => i)
                            .map(el => <option selected={minHour.hour == el} key={el} value={el}>{el}</option>)}
                            </select>
                            :
                            <select className={styles.select} onChange={e => setMinHour(prev => ({...prev, min: e.target.value}))}>{
                            [ ...Array(61).keys()]
                            .map( i => i)
                            .map(el => <option selected={minHour.min == el} key={el} value={el}>{el}</option>)}
                            </select>
                        </div>

                    </div>
                    <div className={styles.inputBlock}>
                        <span>Описание:</span>
                        <textarea value={eventL.summary || ''} onChange={e => setEventL(prev => ({...prev, summary: e.target.value}))}/>
                    </div>
                    
                    {
                        mapList.map(item => 
                            <div className={styles.inputBlock}>
                            <span>{item.name}:
                            <i 
                                className={styles.plus} 
                                onClick={() => 
                                    setEventL(prev => ({...prev, [item.type]: [...prev?.[item.type] || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                            </span>
                            
                                <div className={styles.wrapperInputs}>
                                {
                                    eventL?.[item.type]?.map(el => (
                                        <div className={styles.inputRow}>
                                        <textarea 
                                            className={styles.inputModal}
                                            value={el.text} 
                                            key={el.id} 
                                            onChange={e => 
                                                setEventL(prev => 
                                                    ({...prev, [item.type]: prev[item.type]
                                                        .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                                    )
                                                )
                                            }
                                        />
                                        <i 
                                            title='Удалить'
                                            className={styles.trash} 
                                            onClick={() => 
                                                setEventL(prev => ({...prev, [item.type]: prev[item.type]
                                                    .filter(item => item.id != el.id)}))}><MdDelete/></i>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        )
                    }
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.button} onClick={handleCloseEvent}>Отменить</div>
                        <div className={styles.button} onClick={handleSaveEditEvent}>Сохранить</div>
                    </div>
                </div>
            </div>
  )
}

export default Edit