import * as types from "../actions/types";

const initialState = {
    list: [],
    loading: false,
};

export default function messages (state = initialState, action) {
    switch (action.type) {
      case types.SET_MESSAGE_LIST:
        return {
            ...state,
            list: action.payload
        };
      case types.SET_MESSAGE_LOADING:
        return {
            ...state,
            loading: action.payload
        };
      default:
        return state;
    }
}