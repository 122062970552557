import React from 'react'

export default function BiologicalSituation () {
  return (
    <div style={{display:"flex", flexDirection:"column"}}>

    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-27-04.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-27-28.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-28-27.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-29-14.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-29-39.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-30-30.png'/>
    <img src='https://phb.nintegra.ru/analize/%D0%A0%D0%9E%D0%9E/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%20%D0%BE%D1%82%202023-11-23%2017-30-51.png'/>

    
    </div>
    )
  }