import React, { useState } from 'react'
import {Outlet, Link, useLocation} from 'react-router-dom'
import styles from './styles.module.css'

import {useDispatch} from 'react-redux'
import {signOut} from '../../actions/auth'

import MO_logo from '../../assets/img/layout/MO_logo.png'
import avatar from '../../assets/img/layout/avatar.png'
import header_people from '../../assets/img/layout/header_people.png'
import neiro from '../../assets/img/layout/neiro.png'

import {GiHamburgerMenu} from "react-icons/gi";

import {links, version, mainMenu} from '../../assets/const'
import {getLinkByType} from '../../utils/getLinkByType'
import { useEffect } from 'react'

export default function Layout() {

    const dispatch = useDispatch();
    const params = useLocation();
    const [isShowInfo, setIsShowInfo] = useState(false);
    const [isShowMainMenu, setIsShowMainMenu] = useState(false);
    const [heightHF, setHeightHF] = useState(0);

    const logOut = () => dispatch(signOut())

    const getActive = (link) => {
        return params
            .pathname
            .search(link) == 1;
    }

    useEffect(() => {
        const headerH = document.getElementById('header')?.offsetHeight;
        const footerH = document.getElementById('footer')?.offsetHeight;
        let dopSpace = 0;
        if (params.pathname.includes('/events/')) {
            dopSpace = 0;
        }
        setHeightHF(headerH + footerH - dopSpace);
    })
    return (
        <div>
            <img src={header_people} className={styles.people}/>
            <img src={neiro} className={styles.neiro}/>
            <header className={styles.header} id={'header'}>
                <div className={styles.header_info}>
                    <div className={styles.info_title}>
                        <Link to="/"><img src={MO_logo}/></Link>
                        <h1>Министерство обороны Российской Федерации
                            <br/>
                            <br/>
                        <h2>Автоматизированная система мониторинга
                            <br/>
                            радиационной, химической и биологической обстановки</h2></h1>
                    </div>
                </div>
                <div className={styles.header_person}>
                    <div className={styles.person_block}>
                        {window.innerWidth < 537
                            ? <> {
                                isShowInfo
                                    ? <div className={styles.person_txt}>
                                            <h2>Военный учебно-научный центр Военно-воздушных сил «Военно-воздушная академия
                                                <br/>
                                                имени профессора Н.Е.Жуковского и Ю.А.Гагарина»</h2>
                                            <h3>Надеин Алексей Владимирович, оператор, 27 Научный центр</h3>
                                        </div>
                                    : <div className={styles.btn_logOut} onClick={logOut}>выйти</div>
                            } </>
                :
                  <>
                    <div className = {styles.btn_logOut} onClick = {logOut}> выйти </div>
                  <div
                    className={styles.person_avatar}
                    onClick={() => setIsShowInfo(!isShowInfo)}>
                    <img className={styles.avatar} src={avatar}/>
                    </div>
                  <div className={styles.person_txt}>
                    {/* <h2>Военный учебно-научный центр Военно-воздушных сил «Военно-воздушная академия rt<br/ > имени профессора Н.Е.Жуковского и Ю.А.Гагарина» </h2> */}
                    <h3>Надеин Алексей Владимирович</h3 > 
                    <h3>оператор, 27 Научный центр</h3 > 
                  </div> 
                </>}
                    </div>
                </div>

                {<> {
                    window.innerWidth < 550
                        ? <> <GiHamburgerMenu
                                className={styles.burgerToggle}
                                onClick={() => setIsShowMainMenu(!isShowMainMenu)}/>
                            {
                            isShowMainMenu
                                ? <div className={styles.header_menu}>
                                        <div className={styles.menuWrapper}>
                                            {mainMenu.map((item, i) => <div key={item.name + '_' + i} className={styles.menu_item_wrapper}>
                                                <Link
                                                    to={`/${item.link}`}
                                                    className={styles.menu_item}
                                                    onClick={() => setIsShowMainMenu(false)}>
                                                    {item.name}</Link>

                                                <div className={styles.menu_inner_wrapper}>
                                                    {item
                                                        .innerLinks
                                                        .map(inner => <Link
                                                            to={`/${inner.link}`}
                                                            className={styles.menu_inner__item}
                                                            key={inner.name + '_' + i}
                                                            onClick={() => setIsShowMainMenu(false)}>
                                                            {inner.name}</Link>)}
                                                </div>
                                            </div>)
                                            }
                                        </div>
                                    </div>
                                : <></>}
 
              </> : <div className={styles.header_menu}>
                                        <div className={styles.menuWrapper}>
                                            {mainMenu.map((item, i) => <div key={item.name + '_' + i} className={styles.menu_item_wrapper}>
                                                <Link
                                                    to={`/${item.link}`}
                                                    className={styles.menu_item}
                                                    style={{
                                                    background: getActive(item.link)
                                                        ? '#2671a1'
                                                        : '',
                                                    color: getActive(item.link)
                                                        ? '#fff'
                                                        : '',
                                                }}>
                                                    {item.name}</Link>
                                                <div className={styles.menu_inner_wrapper}>
                                                    {item
                                                        .innerLinks
                                                        .map((inner, i) => <Link to={`/${inner.link}`} key={inner.name + '_' + i} className={styles.menu_inner__item}>{inner.name}</Link>)}
                                                </div>
                                            </div>)
                                              }
                                        </div>
                                    </div>
                        } </>}
            </header>
            <main className={styles.main} style={{height: `${window.innerHeight - heightHF}px`}}>
                <Outlet/>
            </main>
            <footer className={styles.footer} id={'footer'}>
            <span className={styles.version}>{version}</span>
                <div className={styles.wrapper_links}>
                    {links.map((link, i) => <a href={getLinkByType(link)} target={link.blank ? '_blank' : ''} key={link.name  + '_' + i}className={styles.link}>
                        <i>{link.icon}</i>
                        <span>{link.name}</span>
                    </a>)}
                </div>
            </footer>
        </div>
    )
}
