import React from 'react'
import styles from './Meteo.module.css'

const Meteo = () => {
  
    // // const [meteo, setmMeteo] = useState()
    // const [weatherData, setWeatherData] = useState(null);

    // useEffect(() => {
    //     const apiKey = 'f5a3087ad7207c825d9b7eab2398f6a7';
    //     const city = 'Moscow'; // Название города, для которого вы хотите получить данные о погоде
    //     const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}`;
        
    //     fetch(apiUrl)
    //     .then(response => response.json())
    //     .then(data => setWeatherData(data))
    //     .catch(error => console.error('Error fetching weather data:', error));
    //     }, []);
 

    return (
    <div className={styles.wrapper}>
        <div className={styles.meteo}>МЕТЕО</div>
        <iframe className={styles.windy} src="https://embed.windy.com/embed2.html?lat=66.55&lon=94.15&detailLat=66.55&detailLon=94.15&width=650&height=450&zoom=3&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=100" frameBorder="0"></iframe>
    </div>
  )
}

export default Meteo