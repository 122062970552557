import * as types from "../actions/types";

const initialState = {
    list: [],
    count: 0,
    loading: false,
    item: {}
};

export default function DM(state = initialState, action) {
    switch (action.type) {
        case types.GET_DM_LIST:
            return {
                ...state,
                list: action.payload.list,
                count: action.payload.count
            };
        case types.SET_DM_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case types.GET_DM_BY_ID:
            return {
                ...state,
                item: action.payload
            };
        default:
            return state;
    }
}