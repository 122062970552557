import React, { useState, useEffect, useRef } from "react";
import { YMaps, Map, Placemark, Polygon, Circle, TypeSelector, SearchControl } from "react-yandex-maps";

import wrld_1 from '../../assets/img/summary/wrld_1.png'
import wrld_2 from '../../assets/img/summary/wrld_1.png'
import wrld_3 from '../../assets/img/summary/wrld_1.png'
import wrld_4 from '../../assets/img/summary/wrld_4.png'
import geoGreen from '../../assets/img/summary/geoGreen.png'
import geoRed from '../../assets/img/summary/geoRed.png'
import geoYellow from '../../assets/img/summary/geoYellow.png'
import geoBlue from '../../assets/img/summary/geoBlue.png'
import { useNavigate } from "react-router-dom";
import { risk } from "../../assets/const";

export default function Yamap({ 
  coordinate, 
  setItem, 
  width = '340px', 
  heigth = '280px', 
  name = 'geolocation', 
  zoom = 10, 
  center=[55.753994, 37.622093], 
  controls = [], 
  isActiveMap = '',
  events = [],
  isGis = false
}) {
  
  const [markerCoord, setMarkerCoord] = useState(null);
  let navigate = useNavigate();
const three =
[
  [
      [
          0.9924337688937281 * center[0],
  
          0.9848188180304064 * center[1],
      ],
      [
          1.0088844332673137 * center[0],
  
          0.9889918443538016 * center[1],
      ],
      [
          1.0053939864256682 * center[0],
  
          1.0070509755119443 * center[1],
      ],
      [
          1.0008603162695053 * center[0],
  
          1.0174835413204324 * center[1],
      ],
      [
          0.9964085360101086 * center[0],
  
          1.0225919011301066 * center[1],
      ],
      [
          0.9925460413309585 * center[0],
  
          1.0224480036706787 * center[1],
      ],
      [
          0.9943119821369689 * center[0],
  
          1.0048205648908188 * center[1],
      ],
      [
          0.9934435793005509 * center[0],
  
          1.0011511796754193 * center[1],
      ],
      [
          0.9930790920277786 * center[0],
  
          0.9913661524343543 * center[1],
      ],
      [
          0.9924337688937281 * center[0],
  
          0.9848188180304064 * center[1],
      ]
  ]
]

const fourth = [
  [
      [
          0.9924332222222222 * center[0],
  
          0.9848188180304064 * center[1],
      ],
      [
          1.0088844332673137 * center[0],
  
          0.9889918443538016 * center[1],
      ],
      [
          1.0053939864256682 * center[0],
  
          1.0070509755119443 * center[1],
      ],
      [
          1.0008603162695053 * center[0],
  
          1.0174835413204324 * center[1],
      ],
      [
          0.9964085360101086 * center[0],
  
          1.0225919011301066 * center[1],
      ],
      [
          0.9925460413309585 * center[0],
  
          1.0224480036706787 * center[1],
      ],
      [
          0.9943119821369689 * center[0],
  
          1.0048205648908188 * center[1],
      ],
      [
          0.9934435793005509 * center[0],
  
          1.0011511796754193 * center[1],
      ],
      [
          0.9930790920277786 * center[0],
  
          0.9913661524343543 * center[1],
      ],
      [
          0.9924337688937281 * center[0],
  
          0.9848188180304064 * center[1],
      ]
  ]
]

const fifth = [
  [
      [
          0.9924332222222222 * center[0],
  
          0.9848188180304064 * center[1],
      ],
      [
          1.0088844332673137 * center[0],
  
          0.9889918443538016 * center[1],
      ],
      [
          1.0053939864256682 * center[0],
  
          1.0070509755119443 * center[1],
      ],
      [
          1.0008603162695053 * center[0],
  
          1.0174835413204324 * center[1],
      ],
      [
          0.9964085360101086 * center[0],
  
          1.0225919011301066 * center[1],
      ],
      [
          0.9925460413309585 * center[0],
  
          1.0224480036706787 * center[1],
      ],
      [
          0.9943119821369689 * center[0],
  
          1.0048205648908188 * center[1],
      ],
      [
          0.9934435793005509 * center[0],
  
          1.0011511796754193 * center[1],
      ],
      [
          0.9930790920277786 * center[0],
  
          0.9913661524343543 * center[1],
      ],
      [
          0.9924337688937281 * center[0],
  
          0.9848188180304064 * center[1],
      ]
  ]
]

const customIcon = {
  iconLayout: 'default#image',
  // iconImageHref: geoGreen, // путь к вашей кастомной иконке
  iconImageSize: [20, 29], // размеры вашей иконки
  // iconImageOffset: [-15, -30] // смещение иконки
  };

  useEffect(() => {
    const initialMarker = center
    setMarkerCoord(initialMarker);
    setMapaState({
      center : initialMarker, 
      zoom: zoom,
      controls: [],
      // 'searchControl'
      // searchControlProvider: 'yandex#search'
    })
  }, [coordinate]);

  const newMarkerCoords = (e) => {
    setMarkerCoord(e.get("coords"));
    // setItem(e.get("coords"));
  };

  const [mapaState, setMapaState] = useState({
    center: center, 
    controls: [],
    // controls: ['searchControl'],
    // searchControlProvider: 'yandex#search'
    // defaultZoom: 14,
  }
  // , {
  //     suppressMapOpenBlock: true
  // }
  );

  const handleRedirect = (id) => {
    navigate(`/events/${id}`)
  }

  const [text, setText] = useState(null);
  // ref searchcontrol
  const searchRef = useRef(null);

  useEffect(() => {
    if (text && searchRef.current) {
      // Меняем текст searchcontrol при изменении text
      searchRef.current.search(text);
    }
  }, [text]);

  const getColor = (event) => {
    if (event?.incident_type == 'Авария') {
      return geoBlue
    } else if (event?.incident_type == 'Радиоционная') {
      return geoYellow
    } else if (event?.incident_type == 'Химическая') {
      return geoRed
    } else if (event?.incident_type == 'Биологическая') {
      return geoGreen
    } else {
      return geoBlue
    }
  }

  return (
    <YMaps>
      <Map
        width = {width}
        height={heigth}
        defaultState={mapaState} 
        onClick={(e) => newMarkerCoords(e)}
      >
        { isGis &&
          events?.map((event, index) => (
            event.geo_position &&
            <Placemark 
            onClick={() => handleRedirect(event.id)}
            key={index}
            options={{
              ...customIcon, 
              iconImageHref: getColor(event),
              iconImageSize: risk.find(x => x.title == event.incident_type)  ? [27, 29] : [20, 29],
            }}
            properties={{ hintContent: 'Название места' }}
            geometry={
              [
                (event?.geo_position?.split(',')[0])?.replace('{', ''), 
                (event?.geo_position?.split(',')[1])?.replace('}', '')
              ]
            }
            />
            ))
        }

        {markerCoord && !isGis && (
          <Placemark 
          geometry={markerCoord} 
          />
        )}
        {
          isActiveMap == 'one' ? 
          <Circle
          geometry={[center, 50000]}
          options={{
            draggable: false,
            fillColor: "#DB709377",
            strokeColor: "#990066",
            strokeOpacity: 0.8,
            strokeWidth: 2,
            strokeStyle: "shortdash",
          }}
        /> : <></>
        }
        {
          isActiveMap == 'two' ? 
          <Circle
          geometry={[center, 50000]}
          options={{
            draggable: false,
            fillColor: "#DB709377",
            strokeColor: "#990066",
            strokeOpacity: 0.8,
            strokeWidth: 2,
            strokeStyle: "shortdash",
          }}
        /> : <></>
        }
        {
          isActiveMap == 'three' ? 
          <Polygon 
          geometry={three}
          options={[
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            },
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            }
          ]}
          />
          : <></>
        }
        {
          isActiveMap == 'fourth' ? 
          <Polygon 
          geometry={fourth}
          options={[
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            },
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            }
          ]}
          />
          : <></>
        }
        {
          isActiveMap == 'fifth' ? 
          <Polygon 
          geometry={fifth}
          options={[
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            },
            {
              fillColor: "red",
              strokeColor: "red",
              opacity: 0.5,
              strokeWidth: 2,
            }
          ]}
          />
          : <></>
        }
        {
          controls?.find(el => el == 'TypeSelector') ? 
          <TypeSelector options={{ float: "right" }} />
          : <></>
        } 
      </Map>
      
    </YMaps>
  );
}



