import React, { useEffect } from 'react'
import styles from './HighMaps.module.css'
import { Link } from 'react-router-dom';
import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";

import kml_file from '../../assets/111.kml'

import geoGreen from '../../assets/img/summary/geoGreen.png'
import geoRed from '../../assets/img/summary/geoRed.png'
import geoYellow from '../../assets/img/summary/geoYellow.png'
import geoBlue from '../../assets/img/summary/geoBlue.png'

import "leaflet/dist/leaflet.css";
const HighMaps = ({geo, pointList, zoom, width, height, events = []}) => {
    const mapConfig = {
        lat: geo[0],
        lng: geo[1],
        };

    const getColor = (event) => {
      if (event?.incident_type == 'Авария') {
        return geoBlue
      } else if (event?.incident_type == 'Радиоционная') {
        return geoYellow
      } else if (event?.incident_type == 'Химическая') {
        return geoRed
      } else if (event?.incident_type == 'Биологическая') {
        return geoGreen
      } else {
        return geoBlue
      }
    }

    const [kml, setKml] = React.useState(null);

    useEffect(() => {
      fetch('https://phb.nintegra.ru/111.kml')
        .then(res => res.text())
        .then(kmlText => {
          const parser = new DOMParser();
          const kml = parser.parseFromString(kmlText, "text/xml");
          setKml(kml);
        });
    }, [])
    

   return (
    <div>

      <div style={{height: height, width: width, zIndex:0}} className={styles.map}>
      <span className={styles.openLogo}>openstreetmap</span>
      <MapContainer 
        style={{height: height, width: width, zIndex:0}}
        center={mapConfig} 
        zoom={zoom} 
        scrollWheelZoom={true}>
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'/>
      
        {events.map(event => 
          <Marker
            key={event.id}
            position={[
              (event?.geo_position?.split(',')[0])?.replace('{', ''), 
              (event?.geo_position?.split(',')[1])?.replace('}', '')
            ]}
            icon={L.divIcon({
              iconSize: [27, 29],
              iconAnchor: [27, 29],
              className: "mymarker",
              html: "<img src=" + getColor(event) + " />",
            })}
            >
            <Popup>
              <Link to={`/events/${event.id}`}>Перейти</Link>
            </Popup>
          </Marker>
        )}

        {kml && <ReactLeafletKml kml={kml} />}


      </MapContainer>
      </div>
    </div>
  )
}

export default HighMaps