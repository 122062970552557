import React from 'react'
import styles from './styles.module.css'
import {useFormik} from 'formik'
import {Input} from './Components'
// import chika from '../../assets/images/auth/chika.png' import kiosk from
// '../../assets/images/auth/kiosk.png' import svg from
// '../../assets/images/auth/Vector108.svg' import svg2 from
// '../../assets/images/auth/Vector109.svg'
import {useDispatch, useSelector} from 'react-redux'
import {auth} from '../../actions/auth'
import {Navigate} from 'react-router-dom'
import Minoborony from '../../assets/img/minoborony.png'

export default function Auth() {

    const dispatch = useDispatch()
    const {token} = useSelector(state => state.auth)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur
    } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: values => dispatch(auth(values))
    })

    if (token) {
        return <Navigate to='/'/>
    }

    return (
        <div className={styles.wrapper}>

            <header className={styles.header}>
                <img src={Minoborony} alt='o_O'/>
                <h1>Автоматизированная система мониторинга <br/> радиационной, химической и биологической обстановки <br/> Министерства обороны Российской Федерации</h1>
            </header>
            <main>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <h1>Авторизация</h1>
                    <Input
                        name='username'
                        value={values.username}
                        placeholder='E-mail, логин, номер телефона'
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    <Input
                        name='password'
                        type='password'
                        value={values.password}
                        placeholder='Пароль'
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    <button type='submit'>Вход</button>
                </form>

            </main>

            {/* <img src={chika} className={styles.chikaImg} alt='o_O'/>
        <img src={kiosk} className={styles.kioskImg} alt='o_O'/> */}

            <footer className={styles.footer}>
                <div>
                    {/* <img src={svg} alt='o_O'/> */}
                    <span>www.nintegra.ru</span>
                </div>
                <div>
                    {/* <img src={svg2} alt='o_O'/> */}
                    <span>+7(495) 699-00-00</span>
                </div>
                {/* <div className={styles.version}>Версия 2.0</div> */}
            </footer>
        </div>
    )
}
