export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SIGNED_OUT = 'SIGNED_OUT'

export const SET_EVENTS = 'SET_EVENTS'
export const GET_EVENTS = 'GET_EVENTS'
export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING'
export const SET_EVENTS_BY_ID = 'SET_EVENTS_BY_ID'
export const SET_EVENTS_NEWS = 'SET_EVENTS_NEWS'
export const SET_PDF_BY_ID = 'SET_PDF_BY_ID'
export const SET_FILES = 'SET_FILES'
export const SET_ANSWER_LOADING = 'SET_ANSWER_LOADING'
export const SET_ANSWER = 'SET_ANSWER'
export const SET_EVENTS_ASK = 'SET_EVENTS_ASK'
export const SET_EVENTS_ASK_LOADING = 'SET_EVENTS_ASK_LOADING'

export const SET_NEWS = 'SET_NEWS'
export const SET_NEWS_LOADING = 'SET_NEWS_LOADING'
export const SET_MASS_MEDIA = 'SET_MASS_MEDIA'

export const SET_DISEASE_LIST = 'SET_DISEASE_LIST'
export const SET_DISEASE_LOADING = 'SET_DISEASE_LOADING'

export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST'
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING'

export const SET_OPOS_LIST = 'SET_OPOS_LIST'
export const SET_OPOS_LOADING = 'SET_OPOS_LOADING'

export const GET_DM_LIST = 'GET_DM_LIST'
export const SET_DM_LOADING = 'SET_DM_LOADING'
export const GET_DM_BY_ID = 'GET_DM_BY_ID'