import React, { useState } from 'react'
import styles from './Question.module.css'
import { IoCloseCircleOutline } from 'react-icons/io5';
import Button from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux'
import { getAskMore, getEventsAnswer } from '../../../actions/events';
import { useEffect } from 'react';
import { createNotification } from '../../../components/Notifications/Notifications';
import { SET_ANSWER, SET_EVENTS_ASK } from '../../../actions/types';
import Switch from '../../../components/Switch/Switch';

const q_list = [
    {
        id: 1,
        text: 'Подготовь доклад по событию'
    },
]

const Question = ({id, setPopupByType}) => {
    const dispatch = useDispatch();
    const {answer_id, answer_loading, ask = ''} = useSelector(state => state.events);

    const [question, setQuestion] = useState('');
    const [text, setText] = useState('');    
    const [ai, setAi] = useState('niai');
    const [isContext, setIsContext] = useState(true);
    const [isCheck, setIsCheck] = useState(false);
    const [load, setIsLoad] = useState(false);


    const handleClose = () => {
        setPopupByType('');
        dispatch({type: SET_EVENTS_ASK, payload: ''});
    }

    const handleQuestion = () => {
        if (question.trim().length > 0) {
            setText('');
            dispatch({type: SET_EVENTS_ASK, payload: ''});
            dispatch(getEventsAnswer(id, question, ai, isContext));
            setIsCheck(true);
            setIsLoad(true);
        } else {
            createNotification('error', 'Поле с вопроса пусто');
        }
    }

    useEffect(() => {
        let intervalId;
        if (ask?.answer?.trim().length > 0) {
        intervalId = printTextWithDelay(ask?.answer);
        }
        return () => clearInterval(intervalId);
        }, [ask]);
        
        const printTextWithDelay = (text) => {
        let index = -1;
        const id = setInterval(() => {
        if (index < text.length - 1) {
            setText(prev => prev + text[index]);
            index++;
        } else {
        clearInterval(id); // Очистка интервала по завершению печати текста
        }
        }, 70);
        return id; // Возвращаем идентификатор интервала для возможности прерывания
        };

    const handleBaseQuestion = (el) => {
        setQuestion(el.text);
        setText('');
        dispatch({type: SET_ANSWER, payload: ''});
        dispatch(getEventsAnswer(id, el.text, ai, isContext));
        setIsCheck(true);
        setIsLoad(true);
    }

    useEffect(() => {
        let timer = null; 
        if (isCheck) {
        if (ask && ask?.status && ask?.status == 'OK') {
        setIsCheck(false);
        setIsLoad(false);
        } else if (ask && ask?.status && ask?.status == 'ERROR') {
            setIsCheck(false);
            setIsLoad(false);
            createNotification('error', 'Ошибка');
        } else {
        timer = setTimeout(() => {
        answer_id && dispatch(getAskMore(id, answer_id));
        }, 3000);
        }
        }
        return () => clearTimeout(timer);
        }, [ask, answer_id, isCheck]); 

    return (
    <div className={styles.wrapper_bg}>
        <div className={styles.wrapper}>
        <div className={styles.close} onClick={handleClose}><i><IoCloseCircleOutline/></i></div> 
        <div className={styles.title}>Задать вопрос</div>

        <div className={styles.listWrapper}>
            <textarea onChange={(e) => setQuestion(e.target.value)} value={question} className={styles.textarea} placeholder='Текст вопроса'/>
        </div>
        
            <div className={styles.buttonBLock}>
            <Button disabled={load} onClick={handleQuestion} loading={load}>Задать вопрос</Button>
        
            <div className={styles.selectBlock}>
                <span>AI:</span>
                <select className={styles.select} value={ai} onChange={e => setAi(e.target.value)}>
                    <option value='niai'>Nintegra GPT</option>
                    <option value='openai'>Open AI</option>
                    <option value='yandex'>Yandex AI</option>
                </select>
            </div>

            <div className={styles.switchBlock}>
                <span className={styles.switchTitle}>Без контекста события</span>
                <Switch 
                    isChecked={!isContext} 
                    onChange={e => setIsContext(!isContext)}
                    title='Без контекста события'/>
            </div>
            </div>

        <div className={styles.wrapperQ}>
            <div className={styles.title}>Типовые вопросы</div>
            <div className={styles.buttomList}>
                {q_list.map(el => (
                    <button disabled={load} className={styles.qItem} key={el.id} onClick={() => handleBaseQuestion(el)}>{el.text}</button>
                ))}
            </div>
        </div>
        
        <div className={styles.listWrapper}>
            <textarea className={styles.textarea} style={{height: '200px'}} value={text} disabled placeholder='Текст ответа'/>
        </div>

        </div>
    </div>
  )
}

export default Question