import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Input ({label, error, ...props}) {
  
  const inputClassObject = cn(
    styles.input,
    {[styles.error]: error}
  )
  
  return (
    <label className={styles.label}>
        <p>{label}</p>
        <input className={inputClassObject} {...props}/>
        {!!error && <p className={styles.errorText}>{error}</p>}
    </label>
  )
}
