import React, { useEffect } from 'react'
import styles from './Edit.module.css'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../../../assets/fonts/fonts.js'
import { useDispatch } from 'react-redux'
import { SET_PDF_BY_ID } from '../../../../actions/types.js'
import moment from 'moment'
import logo from '../../../../assets/img/minoborony.png'

export default function PDFPrint ({ title, number, min, getPdfById, pdf_list, id, eventL }) {
  const dispatch = useDispatch();
  const createDocData = () => {
    
    const img = new Image();
    addFont(jsPDF.API)
    const orientation = 'p'
    const doc = new jsPDF({ orientation });
    doc.setFont("Montserrat");

    img.src = logo;
    doc.addImage(img, "png", 92, 5, 30, 20);
    doc.setFontSize(10).setFont(undefined, 'bold')
    doc.setFontSize(9).text(`Форма ${number}`, 170, 10)
    doc.setFontSize(9).text(min, 170, 15)
    doc.setFontSize(8).text("МИНИСТЕРСТВО ОБОРОНЫ", 105, 30, "center")
    doc.setFontSize(8).text("РОССИЙСКОЙ ФЕДЕРАЦИИ", 105, 36, "center")
    doc.setFontSize(8).text("ВОЙСКА РАДИАЦИОННОЙ, ХИМИЧЕСКОЙ И БИОЛОГИЧЕСКОЙ ЗАЩИТЫ", 105, 42, "center")
    doc.setFontSize(9).text("ДОКЛАД", 105, 50, "center")
    doc.setFontSize(8).text(title, 110, 55, "center")
    
    doc.autoTable({
      html: `#about`,
      tableLineWidth: 0.4,
      startY: 5,
      startX: 100,
      margin: { left: 3 },
      tableLineColor: '#3f559d',
      tableWidth: 61,
      styles: { font: 'Montserrat', fontSize: 7 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0,
        lineColor: 100,
        fontSize: 10,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineColor: 100,
        lineWidth: 0,
        fontSize: 7,
      },
      didParseCell(data) {
          data.cell.styles.textColor = '#3f559d';
          data.cell.styles.fillColor = '#fff';
      },
    })

      doc.autoTable({
        html: `#form${number}`,
        tableLineWidth: 0,
        startX: 30,
        startY: 62,
        tableWidth: '100%',
        styles: { font: 'Montserrat', fontSize: 9 },
        headStyles: {
          lineWidth: 0,
          lineColor: 100,
          fontSize: 10,
        },
        bodyStyles: {
          // minCellHeight: 1,
          lineColor: 100,
          lineWidth: 0,
          fontSize: 9,
        },
        didParseCell(data) {
            data.cell.styles.fillColor = '#fff';
        },
      })
    
    
    return doc
  }
  
  const toPdf = () => {
    const doc = createDocData();
    doc.save(`Доклад.pdf`);
    dispatch({type: SET_PDF_BY_ID, payload: {}});
  };

  const getPdf = () => {
    dispatch(getPdfById(id, number));
  }

  useEffect(() => {
    if (pdf_list?.id && (number == pdf_list.forma)) {
      toPdf();
    } else return
  }, [pdf_list])
  
  return (
    <div className={styles.pdfBlock}>
      <div onClick={getPdf} title='Скачать' className={styles.pdfButton}>Форма {number}</div>

      <table id='form11' hidden>
        <tr><td>1. Время и дата: {moment(pdf_list.eventtime).format('YYYY.MM.DD') + ` ` + moment(pdf_list.eventtime).format("hh:mm")}</td></tr>
        <tr><td>2. Место возникновения: {pdf_list?.geo_text}</td></tr>
        <tr><td>3. Наименование объекта: {pdf_list?.object_name}</td></tr>
        <tr><td>4. Причины возникновения ЧС: {pdf_list?.incident_type}</td></tr>
        {pdf_list?.source?.map((el, i) => <tr><td>{i == 0 ? '5. От кого получена информация: ' : ''} {el.source_name} </td></tr>)}
        {pdf_list?.involed_agencies?.map((el, i) => <tr><td>{i == 0 ? '6. Список задействованных служб ЛПЧС: ' : ''} {i} </td></tr>)}
        <tr><td>7. Справочные сведения: {pdf_list?.background}</td></tr>

        <tr><td></td></tr>
        {eventL?.recommendations?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.statistic?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.plan?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.chronology?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        
      </table>
      
      <table id='form12' hidden>
        <tr><td>1. Время и дата: {moment(pdf_list.eventtime).format('YYYY.MM.DD') + ` ` + moment(pdf_list.eventtime).format("hh:mm")}</td></tr>
        <tr><td>2. Причины возникновения ЧС: {pdf_list?.incident_type}</td></tr>
        <tr><td>3. Место возникновения: {pdf_list?.geo_text}</td></tr>
        <tr><td>4. Наименование объекта ЧС: {pdf_list?.object_name}</td></tr>
        <tr><td>5. Выполненные первичные мероприятия: {pdf_list?.facility}</td></tr>
        {pdf_list?.completed_activities?.map((el, i) => <tr><td>{i == 0 ? '6. Предложения по первичным защитным мерам: ' : ''} {el} </td></tr>)}
        {pdf_list?.source?.map((el, i) => <tr><td>{i == 0 ? '7. Источник получения информация: ' : ''} {el.source_name} </td></tr>)}
        <tr><td>8. Организация взамодействия: 
        </td></tr>
        <tr><td>9. Дополнительная (Справочная) информация: {pdf_list?.background}</td></tr>
        {pdf_list?.involed_agencies?.map((el, i) => <tr><td>{i == 0 ? '6. Список задействованных служб ЛПЧС: ' : ''} {i} </td></tr>)}
        <tr><td>7. Справочные сведения: {pdf_list?.background}</td></tr>
        <tr><td></td></tr>
        {eventL?.recommendations?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.statistic?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.plan?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
        <tr><td></td></tr>
        {eventL?.chronology?.map(el => <tr key={el.id}><td>{el.text}</td></tr>)}
      </table>

      <table id='about' hidden>
        <tr><td>Сформировано электронном виде</td></tr>
        <tr><td>Автоматизированная система мониторинга</td></tr>
        <tr><td>РХБ обстановки</td></tr>
        <tr><td>{`Оператор: Надеин А.В. ${moment().format('DD.MM.YYYY') + ` ` + moment().format("hh:mm")}`}</td></tr>
      </table>

    </div>
  )
}
