import React, {useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './Slider.module.css'
import 'swiper/css';
import { AiFillCloseSquare } from "react-icons/ai";
import { Navigation } from 'swiper/modules';
import '../../App.css';
import 'swiper/css/navigation';
const Slider = ({data = []}) => {
    const [isShow, setIsShow] = useState('');
    
    const openPopup = (img) => {
        setIsShow(img);
    }

    return (
    <>{
        isShow ?
        <div className={styles.bgPopup}>
            <div className={styles.popup}>
            <i className={styles.close} onClick={() => setIsShow('')}><AiFillCloseSquare color='#dcf2ff' size={25}/></i>
            <img src={isShow} className={styles.popupImg}/>
        </div>
        </div>
        :
        <></>}

        <div className={styles.wrapper}>
            <Swiper
            navigation={true}
            slidesPerView={4}
            speed={600}
            modules={[Navigation]}
            spaceBetween={10}
            pagination={{
                clickable: true,
            }}
            style={{userSelect: 'none'}}
            className="mySwiper">
            {data.map((el, i) =>
            <div style={{width: '100px', userSelect: 'none'}}><SwiperSlide style={{width: '100px'}} key={i} onClick={() => openPopup(el)}><img src={el} className={styles.img}/></SwiperSlide></div>
            )}
            </Swiper>   
        </div>
    </>
  )
}

export default Slider
