import React, { useEffect, useState } from 'react'
import styles from './SingleEvents.module.css'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SET_EVENTS_BY_ID } from '../../actions/types'
import lenta from '../../assets/img/events/lenta.png'
import rbk from '../../assets/img/events/rbk.png'
import tass from '../../assets/img/events/tass.png'
import vesti from '../../assets/img/events/vesti.png'
import Yamap from '../../components/Map/Yamap'
import { locations, risk, levels } from '../../assets/const'
import { BsFillArrowRightSquareFill, BsGeoFill } from "react-icons/bs";
import { editNewEvent, getNewEventById, getEventsNews, getPdfById } from '../../actions/events'
import { MdDelete, MdEdit } from "react-icons/md";
import { IoAdd, IoCloseCircleOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import moment from 'moment'
import Loader from '../../components/Loader/Loader'
import { createNotification } from '../../components/Notifications/Notifications'
import html2canvas from 'html2canvas'
import PDFPrint from '../Events/Popups/Edit/PDFPrint'
import Switch from '../../components/Switch/Switch'
import Files from './Files/Files'
import Photos from './Photo/Photos'
import Question from './Question/Question'

const imgs = [
    {link: 'Lenta.ru',
    img: lenta},
    {link: 'rbc.ru',
    img: rbk},
    {link: 'ТАСС',
    img: tass},
    {link: 'Вести.ru',
    img: vesti}
]

const SingleEvents = ({ }) => {

    const id = useParams().id;
    const {event, events_news, pdf_list} = useSelector(state => state.events);
    const dispatch = useDispatch();
    const [item, setItem] = useState({coordinate: [55.73, 37.9]});
    const [add, setAdd] = useState({type: '', value: ''});
    const [isEditable, setIsEditable] = useState('');
    const [eventL, setEventL] = useState({});
    const [isActiveMap, setIsActiveMap] = useState('meteo');
    const [center, setCenter] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [minHour, setMinHour] = useState({min: moment().minutes(), hour: moment().hour()});
    const [isShowMap, setIsShowMap] = useState(false);
    const [geo_item, setgeo_Item] = useState('');
    const [popupByType, setPopupByType] = useState('');

    useEffect(() => {
        if (event && event.id) {
            setEventL(({...event, 
            geo_position: 
            `${
                    event?.geo_position?.split(',')[0]?.replace('{', '') + ',' + 
                    event?.geo_position?.split(',')[1]?.replace('}', '')
                
            }`|| ''}
            ))
        }
    }, [event])

    useEffect(() => {
        dispatch(getNewEventById(id));
        dispatch(getEventsNews(id));
        return () => {
            dispatch({type: SET_EVENTS_BY_ID, payload: []})
        }
    }, [])

    useEffect(() => {
        setTimeout(function(){
            setShowMap(true);
         }, 500);
    }, [])


    const handleCloseEvent = () => {
        setIsEditable(false);
        dispatch(getEventsNews(id));
    }

    const handleAddEvent = () => {
        const obj = {
            ...eventL
        }
        if (obj.title) {
            const eventL_ = {
                ...obj,
                last_detected: `${moment(obj.last_detected).format('YYYY-MM-DD')} ${minHour.hour}:${minHour.min}`,
                geo_position:    obj.geo_position ? [Number(obj.geo_position.split(',')[0]), Number(obj.geo_position.split(',')[1])] : null
            }
            dispatch(editNewEvent(id, eventL_, 'self'));
        } else {
            createNotification("error", "Заполните обязательные поля");
        }
        setIsEditable(false);
    }

    const handleEdit = (text, _id, name) => {
        let copyNewEvent = eventL?.[name] ? eventL[name] : []; 
        copyNewEvent = copyNewEvent.map(el => el.id != _id ? el : {...el, text: text});
        const newEvent_ = {...eventL, [name]: copyNewEvent};
        setEventL(newEvent_);
    }

    const geo_text = (text) => {
        fetch(`https://geocode-maps.yandex.ru/1.x?apikey=9e115931-69dc-4f36-b58c-ce53f85a6f89&geocode=${text}&format=json`)
        .then(res => res.json()).then(data => {
            const coord = data && data?.response?.GeoObjectCollection.featureMember?.[0]?.GeoObject?.Point?.pos.split(' ');
            setCenter([Number(coord[1]), Number(coord[0])])
            dispatch(editNewEvent(id, {...event, geo_position: [Number(coord[1]), Number(coord[0])] }));
        })
    }

    useEffect(() => {
        if (event && event?.geo_text && event?.geo_position==null) {
            geo_text(event?.geo_text);
        } else if (event && event.geo_position!=null) {
            const coord = event?.geo_position?.split(',');
            setCenter([Number(coord[0]?.replace('{', '')), Number(coord[1]?.replace('}', ''))]);
        }
    }, [event?.geo_text, event?.geo_position, event?.id])

    useEffect(() => {
        if (geo_item) {
            setEventL(({...eventL, geo_position: geo_item.join(',')}))
            setIsShowMap(false);   
        }
    }, [geo_item])

    const handlePrintPage = () => {
        const element = document.body;
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "no";
        html2canvas(element, {scrollX: 0, scrollY: 0, windowWidth: '100%', windowHeight: '100%'}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.href = imgData;
            downloadLink.download = 'screenshot.png';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            document.documentElement.style.overflow = '';
            document.body.scroll = "yes";
            });
    }
    
    const handlePopupType = (type) => {
        setPopupByType(type);
    }

    return (
    <>    
        {popupByType === 'file' ? <Files id={id} setPopupByType={setPopupByType} files={event.files}/> : <></>}
        {popupByType === 'photo' ? <Photos id={id} setPopupByType={setPopupByType} files={event.images}/> : <></>}
        {popupByType === 'question' ? <Question id={id} setPopupByType={setPopupByType}/> : <></>}
        {isEditable ?
            <div className={styles.popup}>
                <div className={styles.popupContent}>
                    <div className={styles.popupContentInner}>
                    <div className={styles.close} onClick={handleCloseEvent}><IoCloseCircleOutline fontSize={35}/></div>
                    <div className={styles.titleMain}>
                        Редактирование события
                                            
                    <div className={styles.switchBlock}>
                    <span className={styles.switchTitle}>Взять на контроль</span>
                    <Switch 
                        isChecked={eventL.control} 
                        onChange={e => setEventL(prev => ({...prev, control: !prev.control}))}
                        title='Взять под контроль'
                    />
                    </div>
                        </div>

                    <div className={styles.inputBlock}>
                        <span>Заголовок*:</span>
                        <input type="text" value={eventL.title || ''} onChange={e => setEventL(prev => ({...prev, title: e.target.value}))}/>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Тип:</span>
                        <select onChange={e => setEventL(prev => ({...prev, incident_type: e.target.value}))} className={styles.selectType}>
                            {risk.map(el => <option key={el.title} selected={el.title == eventL.incident_type}>{el.title}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Уровень:</span>
                        <select onChange={e => setEventL(prev => ({...prev, level: e.target.value}))} className={styles.selectType}>
                            {levels.map(el => <option key={el.label} selected={el.value == eventL.level}>{el.label}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Адрес:</span>
                            <input type="text" value={eventL.geo_text || ''} onChange={e => setEventL(prev => ({...prev, geo_text: e.target.value}))}/>
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Локация:</span>
                        <select onChange={e => setEventL(prev => ({...prev, location: e.target.value}))} className={styles.select_loc}>
                            {locations.map(el => <option key={el.name} selected={el.name == eventL.location}>{el.name}</option>)}
                        </select>
                    </div>

                    <div className={styles.inputBlock}>
                            <span title='[Широта, Долгота]'>Координаты:</span>
                            <input 
                                style={{width: `calc(100% - 60px)`}} 
                                type="text" 
                                value={eventL.geo_position || ''} 
                                onChange={e => setEventL(prev => ({...prev, geo_position: e.target.value}))}
                            />
                            <i 
                                className={styles.mapIcon} 
                                onClick={() => setIsShowMap(true)}
                                title='Открыть карту'
                                ><BsGeoFill/></i>
                    
                            {
                                isShowMap ?
                                <div className={styles.map_select}>
                                    <Yamap 
                                    coordinate={geo_item} 
                                    width={'100%'} heigth="100%" 
                                    setItem={setgeo_Item} 
                                    zoom={1}
                                    center={geo_item || [55.73, 37.9]}
                                    name="byName"/>
                                </div>
                                : <></>
                            }
                    </div>

                    <div className={styles.inputBlock}>
                            <span>Дата события:</span>
                        <div className={styles.inputBlockInner} style={{width: '100%'}}>
                            <input type="last_detected" value={moment(eventL.last_detected).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')} onChange={e => setEventL(prev => ({...prev, last_detected: e.target.value}))}/>
                            <select className={styles.select} onChange={e => setMinHour(prev => ({...prev, hour: e.target.value}))}>{
                            [ ...Array(24).keys()]
                            .map( i => i)
                            .map(el => <option selected={minHour.hour == el} key={el} value={el}>{el}</option>)}
                            </select>
                            :
                            <select className={styles.select} onChange={e => setMinHour(prev => ({...prev, min: e.target.value}))}>{
                            [ ...Array(61).keys()]
                            .map( i => i)
                            .map(el => <option selected={minHour.min == el} key={el} value={el}>{el}</option>)}
                            </select>
                        </div>

                    </div>
                    <div className={styles.inputBlock}>
                        <span>Описание:</span>
                        <textarea value={eventL.summary || ''} onChange={e => setEventL(prev => ({...prev, summary: e.target.value}))}/>
                    </div>

                    <div className={styles.inputBlock}>
                    <span>Рекомендации:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, recommendations: [...prev?.recommendations || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                    
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.recommendations?.map(el => (
                                <div className={styles.inputRow}>
                                <textarea 
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, recommendations: prev.recommendations
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, recommendations: prev.recommendations
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    
                    <div className={styles.inputBlock}>
                    <span>Статистика:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, statistic: [...prev?.statistic || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                    
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.statistic?.map(el => (
                            <div className={styles.inputRow}>
                                <textarea
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, statistic: prev.statistic
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, statistic: prev.statistic
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    
                    <div className={styles.inputBlock}>
                    <span>План:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, plan: [...prev?.plan || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                    
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.plan?.map(el => (
                                <div className={styles.inputRow}>
                                <textarea
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, plan: prev.plan
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                 <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, plan: prev.plan
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    
                    <div className={styles.inputBlock}>
                    <span>Организации-участники события:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, involved_agencies: [...prev?.involved_agencies || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                    
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.involved_agencies?.map(el => (
                                <div className={styles.inputRow}>
                                <textarea
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, involved_agencies: prev.involved_agencies
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                 <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, involved_agencies: prev.involved_agencies
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                                </div>
                            ))
                        }
                        </div>
                    </div>

                    <div className={styles.inputBlock}>
                    <span className={styles.title}>Хронология:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, chronology: [...prev?.chronology || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.chronology?.map(el => (
                            <div className={styles.inputRow}>
                                <textarea
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, chronology: prev.chronology
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, chronology: prev.chronology
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                            </div>
                            ))
                        }
                        </div>
                    </div>

                    <div className={styles.inputBlock}>
                    <span>Метеорологическая обстановка:
                    <i 
                        className={styles.plus} 
                        onClick={() => 
                            setEventL(prev => ({...prev, meteo: [...prev?.meteo || [], {id: Date.now(), text: ''}] }))}><IoAdd/></i>
                    </span>
                    
                        <div className={styles.wrapperInputs}>
                        {
                            eventL?.meteo?.map(el => (
                            <div className={styles.inputRow}>
                                <textarea
                                    className={styles.inputModal}
                                    value={el.text} 
                                    key={el.id} 
                                    onChange={e => 
                                        setEventL(prev => 
                                            ({...prev, meteo: prev.meteo
                                                .map(item => item.id == el.id ? {...item, text: e.target.value} : item)}
                                            )
                                        )
                                    }
                                />
                                <i 
                                    title='Удалить'
                                    className={styles.trash} 
                                    onClick={() => 
                                        setEventL(prev => ({...prev, meteo: prev.meteo
                                            .filter(item => item.id != el.id)}))}><MdDelete/></i>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.button} onClick={handleCloseEvent}>Отменить</div>
                        <div className={styles.button} onClick={handleAddEvent}>Сохранить</div>
                    </div>
                </div>
            </div>
            : null
        }

        <div className={styles.mainTitle}>
            <div className={styles.mainTitleEvent}>Событие №{eventL?.id}: <span>{eventL?.title}</span></div>
            
            {isEditable != 'chronology' ? <i className={styles.editIcon} title="Редактировать" onClick={() => setIsEditable('chronology')}><MdEdit/></i> : <></>}
            <div className={styles.mainTitleTime}>

        <div className={styles.typeRisk}>
            {eventL?.incident_type ? <div className={styles.typeRiskItem}>Тип: {eventL?.incident_type}</div> : <></>}
            {eventL?.level ? <div className={styles.typeLevel}>Уровень: {eventL?.level}</div> : <></>}
            <div>{eventL.control ? <div className={styles.control}>На контроле</div> : ''}</div>   
        </div>
                Дата и время: <span>{moment(eventL?.date).format('DD.MM.YYYY HH:mm')}</span>
            
            <div className={styles.pdfBlock}>
            <i className={styles.questionBlock} onClick={() => handlePopupType('question')} title='Задать вопрос'><FaQuestionCircle/></i>
            <div className={styles.buttonPrint} onClick={() => handlePopupType('file')}>Файлы</div>
            <div className={styles.buttonPrint} onClick={() => handlePopupType('photo')}>Фото</div>
            <div className={styles.buttonPrint} onClick={handlePrintPage}>Печать</div>
            
            <PDFPrint  
                title='об обнаружении факта радиационного, химического и биологического заражения (загрязнения)' 
                min='Не позднее 5 мин.' 
                number={11}
                getPdfById={getPdfById}
                pdf_list={pdf_list}
                // pdf_list_2={{}}
                id={eventL.id}
                eventL={eventL}
                />
            <PDFPrint  
                title='о РХБО в зоне (районе) ЧС' 
                min='Не позднее 30 мин.' 
                number={12}
                getPdfById={getPdfById}
                // pdf_list_1={{}}
                pdf_list={pdf_list}
                id={eventL.id}
                eventL={eventL}
            />

            </div>
            </div>
        </div>
    <div className={styles.wrapper}>
        <div className={styles.wrapperMap}>
            <div className={styles.map}>
            {
                center && center.length > 0 ?
                <Yamap 
                coordinate={item?.coordinate} 
                width={'100%'} heigth="100%" 
                setItem={setItem} 
                zoom={15}
                center={center}
                options={{ searchControlProvider: "yandex#search" }}
                name="byName"/>
                : <></>
            }
            </div>
            <div className={styles.info}>
                <div className={styles.title_info}>Информация в СМИ ({events_news?.length ? events_news?.length : 0} упоминаний)</div>
                <div className={styles.more}>смотреть полность</div>

                <div className={styles.infoList}>
                    {events_news.length ? events_news?.map(el => <a href={el.article_url} target='_blank' className={styles.infoListItem} key={el.article_id}>
                        <span className={styles.infoListItemTitle}>{el.article_ingress_translated || el.article_ingress}</span>
                        <div className={styles.link}>{el.source_name}</div>
                    </a>)
                    : <></>}
                </div>
                <div className={styles.resource}>
                    <div className={styles.resource_img}>Источники:</div>
                    {imgs.map((el, i) => <img className={styles.resource_img} src={el.img} key={i}/>)}
                </div>
            </div>
        </div>
        <div className={styles.wrapperEvent}>
            <div className={styles.forecast}>
                <div className={styles.title_forecast}>Прогноз</div>
            
                <div className={styles.mapsList}>
                    <div className={styles.mapsListItem}>
                        <div className={styles.mapsListItemMap}>
                        {!showMap && <Loader/>}
                        {
                            isActiveMap != 'meteo' && showMap && center && center.length > 0 ?
                            <Yamap 
                            coordinate={item?.coordinate} 
                            width={'100%'} heigth="100%" 
                            setItem={setItem} 
                            zoom={9}
                            center={center}
                            isActiveMap={isActiveMap}
                            name="place1"/>
                            : <></>
                        }
                            {
                                isActiveMap == 'meteo' ? 
                                <div className={styles.meteo}>
                                    <div className={styles.meteoTitle}>Прогноз</div>
                                    <iframe 
                                        className={styles.windy} 
                                        src={`https://embed.windy.com/embed2.html?lat=${center?.[0]}&lon=${center?.[1]}&detailLat=${center?.[0]}&detailLon=${center?.[1]}}&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1`} 
                                        frameBorder="0">
                                    </iframe>
                                </div>
                                : <></>
                            }
                        
                        </div>
                        <i className={styles.arrow}><BsFillArrowRightSquareFill color='#e9e9e8' size={20}/></i>
                    </div>
                </div>

                <div className={styles.buttonsList}>
                    <button onClick={() => setIsActiveMap('one')} className={styles[isActiveMap == 'one' ? 'buttonItem_active' : 'buttonItem']}>с учетом количества АХОВ</button>
                    <button onClick={() => setIsActiveMap('meteo')} className={styles[isActiveMap == 'meteo' ? 'buttonItem_active' : 'buttonItem']}>МЕТЕО</button>
                    <button onClick={() => setIsActiveMap('two')} className={styles[isActiveMap == 'two' ? 'buttonItem_active' : 'buttonItem']}>с учетом АХОВ</button>
                    <button onClick={() => setIsActiveMap('three')} className={styles[isActiveMap == 'three' ? 'buttonItem_active' : 'buttonItem']}>с учетом рельефа</button>
                    <button onClick={() => setIsActiveMap('fourth')} className={styles[isActiveMap == 'fourth' ? 'buttonItem_active' : 'buttonItem']}>с учетом ландшафта</button>
                    <button onClick={() => setIsActiveMap('fifth')} className={styles[isActiveMap == 'fifth' ? 'buttonItem_active' : 'buttonItem']}>с учетом инфаструктуры</button>
                </div>

            </div>
            <div className={styles.chronology} id="chronology">
            <div className={styles.title_chronology}>Хронология</div>
            <div className={styles.more}>смотреть полность</div>
            
            {add.type == 'chronology' ? 
                <><input className={styles.inputAdd} type="text" onChange={e => setAdd({type: 'chronology', value: e.target.value})} value={add.value}/></> 
                : <></>}
            
            <div className={styles.blockquoteWrapper} style={{maxHeight: document.getElementById('chronology')?.clientHeight - 30 + 'px'}}>
            {(event && event?.chronology) ? (event?.chronology).map(el => 
            <blockquote 
            onInput={e => handleEdit(e.currentTarget.textContent, el.id, 'chronology')}
            contentEditable={isEditable=='chronology' ? true : false}
            className={styles[isEditable=='chronology' ? 'active' : 'chronologyItem']} 
            key={el.id}>
                {el.text}
            </blockquote>
            )
            : <></>}
            </div>
            </div>
        </div>
        <div className={styles.wrapperInfo}>
            <div className={styles.recommend} id="recommendations">
                <div className={styles.more}>смотреть полность</div>
                <div className={styles.title_recommend}>Рекомендации</div>

                {add.type == 'recommendations' ? 
                    <input className={styles.inputAdd} type="text" onChange={e => setAdd({type: 'recommendations', value: e.target.value})} value={add.value}/> 
                    : <></>}
                
                <div className={styles.blockquoteWrapperRecom} style={{maxHeight: document.getElementById('recommendations')?.clientHeight - 30 + 'px'}}>
                {(event && event?.recommendations) ? (event?.recommendations).map(el => 
                <blockquote 
                onInput={e => handleEdit(e.currentTarget.textContent, el.id, 'recommendations')}
                contentEditable={isEditable=='recommendations' ? true : false}
                className={styles[isEditable=='recommendations' ? 'active' : 'chronologyItem']}
                key={el.id}>
                    {el.text}
                </blockquote>
                )
                : <></>}
                </div>
            </div>
            <div className={styles.stat} id="stat">
                <div className={styles.more}>смотреть полность</div>
                <div className={styles.title_stat}>Статистика</div>
                    
                    {add.type == 'statistic' ? 
                        <input className={styles.inputAdd} type="text" onChange={e => setAdd({type: 'statistic', value: e.target.value})} value={add.value}/> 
                        : <></>}
                    
                    <div className={styles.blockquoteWrapperState} style={{maxHeight: document.getElementById('stat')?.clientHeight - 30 + 'px'}}>
                    {(event && event?.statistic) ? (event?.statistic).map(el => 
                    <blockquote 
                    onInput={e => handleEdit(e.currentTarget.textContent, el.id, 'statistic')}
                    contentEditable={isEditable=='statistic' ? true : false}
                    key={el.id}
                    className={styles[isEditable=='statistic' ? 'active' : 'chronologyItem']}>
                        {el.text}
                    </blockquote>
                    )
                    : <></>}
                    </div>
            </div>
            <div className={styles.plan} id="plan">
                <div className={styles.more}>смотреть полность</div>
                <div className={styles.title_plan}>План устранения</div>
                <div className={styles.planList} style={{maxHeight: document.getElementById('plan')?.clientHeight - 30 + 'px'}}>
            
                    {add.type == 'plan' ? 
                        <input className={styles.inputAdd} type="text" onChange={e => setAdd({type: 'plan', value: e.target.value})} value={add.value}/> 
                        : <></>}
                    
                    <div className={styles.blockquoteWrapperPlan}>
                    {(event && event?.plan) ? (event?.plan).map(el => 
                    <blockquote 
                    onInput={e => handleEdit(e.currentTarget.textContent, el.id, 'plan')}
                    contentEditable={isEditable=='plan' ? true : false}
                    className={styles[isEditable=='plan' ? 'active' : 'chronologyItem']} key={el.id}>
                        {el.text}
                    </blockquote>
                    )
                    : <></>}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default SingleEvents