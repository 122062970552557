import { GET_DM_LIST, SET_DM_LOADING, GET_DM_BY_ID } from "./types";
import {createNotification} from "../components/Notifications/Notifications";
import moment from "moment";

export const getDM = () => async(dispatch, getState) => {
    dispatch({ type: SET_DM_LOADING, payload: true });
    const response = await fetch(`https://phb.nintegra.ru/api/dms`);
    if (response.status == 200) {
      const data = await response.json(); 
      dispatch({type: GET_DM_LIST, payload: {count: data.numDm, list: data.Dm}});
    } else {
      createNotification("error", "Ошибка при получении списка веществ")
      dispatch({type: GET_DM_LIST, payload: []});
    }
    dispatch({ type: SET_DM_LOADING, payload: false });
  };

export const getDMById = (id) => async(dispatch, getState) => {
    dispatch({ type: SET_DM_LOADING, payload: true });
    const response = await fetch(`https://phb.nintegra.ru/api/dm/${id}`);
    if (response.status == 200) {
      const data = await response.json(); 
      dispatch({type: GET_DM_BY_ID, payload: data});
    } else {
      createNotification("error", "Ошибка при получении вещества")
      dispatch({type: GET_DM_BY_ID, payload: []});
    }
    dispatch({ type: SET_DM_LOADING, payload: false });
  };

  export const setNewDM =
  (data) => async (dispatch, getState) => {
    dispatch({ type: SET_DM_LOADING, payload: true });
    if (1) {
      const response = await fetch(`https://phb.nintegra.ru/api/dm`, {
          method: "POST",
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },  
          body: JSON.stringify(data),
      });

      if (response.ok) {
        dispatch(getDM());
        createNotification("success", "Вещество добавлено")
      } else {
        createNotification("error", "Вещество не добавлено")
      }
    }
    dispatch({ type: SET_DM_LOADING, payload: false });
  };

  export const editDM = (data) => async (dispatch, getState) => {
    dispatch({ type: SET_DM_LOADING, payload: true });
    if (1) {
        const response = await fetch(`https://phb.nintegra.ru/api/dm/${data.id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
              }, 
        })
        if (response.ok) {
            createNotification("success", "Изменено")
            dispatch(getDMById(data.id));
        } else {
            createNotification("error", "Ошибка")
        }
    }
    dispatch({ type: SET_DM_LOADING, payload: false });
  }

  export const deleteDM = (id) => async (dispatch, getState) => {
    dispatch({ type: SET_DM_LOADING, payload: true });
    // await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`https://phb.nintegra.ru/api/dm/${id}`, {
        method: "DELETE",
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }, 
      });
      if (response.ok) {
        dispatch(getDM());
      }
    }
    dispatch({ type: SET_DM_LOADING, payload: false });
  };
