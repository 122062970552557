import storage from 'redux-persist/es/storage';
import {persistCombineReducers} from 'redux-persist';

import events from './events'
import auth from './auth';
import news from './news';
import disease from './disease';
import messages from './message';
import opos from './opos';
import dm from './dm';

const storageConfig = {
    whitelist: 'auth',
    events: 'events',
    news: 'news',
    dm: 'dm',
    key: 'certification_storage',
    storage
};

export default persistCombineReducers(storageConfig, {auth, events, news, disease, messages, opos, dm});
