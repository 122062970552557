import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDisease, getDiseaseList, updateDisease, createDisease } from '../../actions/disease'
import { useEffect } from 'react'
import Li from '../../components/Li'
import Loader from '../../components/Loader/Loader'
import Modal from 'react-responsive-modal'
import { useState } from 'react'
import { useFormik } from 'formik'
import Button from '../../components/Button'
import { Input } from '../../components/ModalInputs'
import { IoMdClose } from "react-icons/io";
import * as Yup from 'yup'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле'),
  code: Yup.string().required('обязательное поле'),
})


const initialFormValues = {
  name: '',
  code: ''
}

export default function Disease () {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.disease)
  
  const [selectedDisease, setSelectedDisease] = useState(null)
  
  useEffect(() => {
    dispatch(getDiseaseList())
  }, [])
  
  const renderLi = (disease) => {
    
    return (
      <Li key={disease.id} onClick={() => setSelectedDisease(disease)}>
        <div className={styles.firstLiBlock}>
          <div>{disease.name}</div>
          <div>{disease.code}</div>
        </div>
        <IoMdClose color='red' size={24} onClick={deleteDiseaseHandler(disease.id)}/>
      </Li>
    )
  }

  const deleteDiseaseHandler = (id) => (e) => {
    e.stopPropagation()
    dispatch(deleteDisease(id))
  }

  const closeModal = () => {
    setSelectedDisease(null)
  }
  
  return (
    <div className={styles.wrapper}>
      <Button onClick={() => setSelectedDisease({})}>Добавить заболевание</Button>
      <h2>Список опасных заболеваний</h2>
      {loading
        ? <Loader top='30%' left='46.5%'/>
        : !!list.length && <ul>{list.map(renderLi)}</ul>
      }
      <DiseaseModal
        open={!!selectedDisease}
        onClose={closeModal}
        disease={selectedDisease}
        addMode={!selectedDisease?.id}
      />
    </div>
  )
}


const DiseaseModal = ({ open, onClose, disease, addMode }) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    open && setValues(addMode ? initialFormValues : disease)
  }, [open])
  
  const submitForm = ({id, ...values}) => {
    id ? dispatch(updateDisease(id, values)) : dispatch(createDisease(values))
    onClose()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit,
    handleBlur,
    setValues
  } = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm
  })
  
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={open}
      classNames={{ modal: styles.modal }}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >

      <h2>{addMode ? 'Добавление заболевания' : 'Редактирование заболевания'}</h2>

      <Input
        style={{width: '80%'}}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        name='name'
        label='Название'
        error={error('name')}
        />

      <Input
        style={{width: '40%'}}
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        name='code'
        label='Код'
        error={error('code')}
      />

      <div className={styles.buttonsBlock}>
        <Button onClick={handleSubmit}>Сохранить</Button>
        <Button onClick={onClose}>Закрыть</Button>
      </div>

    </Modal>
  )
}