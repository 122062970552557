import ReactPaginate from "react-paginate"
import styles from "./Pagination.module.css"



export const Pagination = ({
    pageCount,
    onPageChange = () => {},
    numberPage = 0
}) => {

    return (
        <div className={styles.wrapper}>
            <ReactPaginate
            previousLabel={numberPage > 0 && "Предыдущая"}
            nextLabel={pageCount-numberPage > 1 && "Следующая"}
            breakLabel={"..."}
            breakClassName="break-me"
            marginPagesDisplayed={2}
            pageCount={pageCount || null}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            containerClassName={styles.pagination}
            subContainerClassName="pages pagination"
            activeClassName={styles.active}
            forcePage={numberPage}
            />
        </div>
    )
}