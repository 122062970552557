import React from 'react'
import styles from './styles.module.css'


export default function Input ({label, ...props}) {
  return (
    <label className={styles.label}>
        <p>{label}</p>
        <textarea className={styles.input} {...props}/>
    </label>
  )
}
