import React, {useState} from 'react'
import styles from './Select.module.css'

const Select = ({options, name}) => {
    const [value, setValue] = useState('');
    return (
    <div className={styles.wrapperSelect}>
        <select onChange={e => setValue(e.target.value)} className={styles.select}>
            {options.map(el => <option value={el.value} key={el.value} selected={el.value === value}>{el.name}</option>)}
        </select>
        <span>{name}</span>
    </div>
  )
}

export default Select